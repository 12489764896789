import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
    name: 'birthdayToAge',
})
export class BirthdayToAgePipe implements PipeTransform {
    transform(value: string): unknown {
        const date = dayjs(value, 'DD.MM.YYYY');

        if (value && date.isValid()) {
            return dayjs().diff(date, 'year');
        }

        return '-';
    }
}
