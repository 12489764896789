import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AoFilterHeaderField } from '../ao-filter-list-header.component';

@Component({
    selector: 'app-filter-select',
    templateUrl: './filter-select.component.html',
    styleUrls: ['./filter-select.component.scss'],
})
export class FilterSelectComponent implements OnInit {
    @Input() field!: AoFilterHeaderField;
    @Input() control!: UntypedFormControl;

    private _filter!: any;
    get filter(): any {
        return this._filter;
    }
    @Input() set filter(value: any) {
        this._filter = value;
        this.setValues();
    }

    @Output() sortChange = new EventEmitter<void>();

    ngOnInit(): void {
        this.setValues();
    }

    private setValues(): void {
        if (this.control.value?.length) {
            if (this.control.value === 'true') {
                this.control.setValue(true, { emitEvent: false });
            } else if (this.control.value === 'false') {
                this.control.setValue(false, { emitEvent: false });
            }
        }
    }

    clear(): void {
        this.control.reset();
    }
}
