import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-delayed-button',
    templateUrl: './delayed-button.component.html',
    styleUrls: ['./delayed-button.component.scss'],
})
export class DelayedButtonComponent implements OnInit {
    @Input() activeColor = '#EB5153';
    @Input() inactiveColor = '#DCDCDC';
    @Input() icon = 'delete';
    @Input() iconColor = '#0000003D';

    @Output() finished = new EventEmitter<void>();

    percent = 0;
    private interval?: NodeJS.Timer;

    ngOnInit() {
        this.percent = 0;
    }

    get style(): { [key: string]: string } {
        return {
            background: `linear-gradient(to top, ${this.activeColor} ${this.percent}%, transparent 0%)`,
        };
    }

    start(): void {
        this.percent = 0;
        this.interval = setInterval(() => {
            this.percent += 4;
            if (this.percent === 100) {
                clearInterval(this.interval);
            }
        }, 10);
    }

    onFinished(): void {
        if (this.percent === 100) {
            this.finished.emit();
        }
        this.stop();
    }

    stop(): void {
        this.percent = 0;
        clearInterval(this.interval);
    }
}
