import { PdfJob, PdfJobStatus } from '@ah-oh/ng-pdf-api';
import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-download-badge',
    templateUrl: './download-badge.component.html',
    styleUrls: ['./download-badge.component.scss'],
})
export class DownloadBadgeComponent {
    private _pdfJobs: PdfJob[] = [];
    get pdfJobs(): PdfJob[] {
        return this._pdfJobs;
    }
    @Input() set pdfJobs(value: PdfJob[]) {
        this._pdfJobs = value;
        this.setBadge();
    }

    @Input() pdfMenuSeen = true;

    notDownloaded = 0;
    isProcessing = false;

    private readonly destroy = new Subject<void>();

    setBadge(): void {
        this.isProcessing = !!this.pdfJobs.find((j) => j.status === PdfJobStatus.PROCESSING);

        this.notDownloaded = this.pdfJobs.filter((j) => !j.downloaded).length;
    }
}
