<div class="confirm-container">
    <div class="button">
        <button mat-flat-button style="margin-bottom: 20px" (click)="close(false)" [disabled]="disabled">
            <mat-icon>clear</mat-icon>
            Abbrechen
        </button>
    </div>
    <div class="center-div">
        <div>
            <h1 [ngStyle]="{ color: data.lockedBackgroundColor }" class="headline">{{ data.headline }}</h1>
            <div class="content" [innerHTML]="data.content"></div>
            <div class="confirm" *ngIf="!data.buttonText">
                <app-unlock
                    [text]="data.confirmText"
                    [big]="true"
                    [lockedBackgroundColor]="data.lockedBackgroundColor"
                    [unlockedBackgroundColor]="data.unlockedBackgroundColor"
                    (unlocked)="close(true)"
                    (locked)="close(false)"
                ></app-unlock>
            </div>

            <div *ngIf="data.buttonText">
                <button mat-flat-button (click)="dialogRef.close(true)" color="accent">{{ data.buttonText }}</button>
            </div>
        </div>
    </div>
    <div *ngIf="data.info" class="info-box">
        <div><mat-icon>info</mat-icon></div>
        <div>{{ data.info }}</div>
    </div>
</div>
