import { AoAuthUser } from '@ah-oh/company-auth';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PermissionSection, User } from '../models/user.interface';
import { UsersService } from './users.service';
@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    private readonly permissionChange = new Subject<void>();
    $permissionChange = this.permissionChange.asObservable();

    constructor(private readonly usersService: UsersService) {}

    private get user(): (AoAuthUser & User) | undefined {
        return this.usersService.myUser;
    }

    get ADMIN(): boolean {
        return this.user?.role === 'admin';
    }

    get DONATION_ENTRANCE(): boolean {
        if (this.ADMIN) {
            return true;
        }
        return !!this.user?.permissions.includes(PermissionSection.DONATION_ENTRANCE);
    }

    get DONATORS(): boolean {
        if (this.ADMIN) {
            return true;
        }
        return !!this.user?.permissions.includes(PermissionSection.DONATORS);
    }

    get PROJECTS(): boolean {
        if (this.ADMIN) {
            return true;
        }
        return !!this.user?.permissions.includes(PermissionSection.PROJECTS);
    }

    get EVALUATION(): boolean {
        if (this.ADMIN) {
            return true;
        }
        return !!this.user?.permissions.includes(PermissionSection.EVALUATION);
    }

    get SETTINGS(): boolean {
        if (this.ADMIN) {
            return true;
        }
        return !!this.user?.permissions.includes(PermissionSection.SETTINGS);
    }

    get CHANNEL_IMPORT(): boolean {
        if (this.ADMIN) {
            return true;
        }
        return !!this.user?.permissions.includes(PermissionSection.CHANNEL_IMPORT);
    }

    get RECEIPTS(): boolean {
        if (this.ADMIN) {
            return true;
        }
        return !!this.user?.permissions.includes(PermissionSection.RECEIPTS);
    }

    get COLLECTION_LETTERS(): boolean {
        if (this.ADMIN) {
            return true;
        }
        return !!this.user?.permissions.includes(PermissionSection.COLLECTION_LETTERS);
    }
}
