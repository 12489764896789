<div class="field-container">
    <input
        class="input"
        [type]="isNumber ? 'number' : 'text'"
        [formControl]="control"
        [placeholder]="field.placeholder"
    />
    <mat-icon
        *ngIf="sortable; else searchIcon"
        (click)="sortChange.emit()"
        class="sort-icon"
        [class.active]="field.sortState"
        [class.rotate]="field.sortState === 'desc'"
        >north</mat-icon
    >

    <ng-template #searchIcon><mat-icon>search</mat-icon></ng-template>
</div>
