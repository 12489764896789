<button
    mat-icon-button
    [style.color]="inactiveColor"
    [ngStyle]="style"
    [ngClass]="percent === 0 ? 'inactive' : 'active'"
    (mousedown)="start()"
    (touchstart)="$event.preventDefault(); start()"
    (mouseup)="onFinished()"
    (touchend)="onFinished()"
    (mouseleave)="stop()"
    (touchmove)="stop()"
    (touchcancel)="stop()"
>
    <mat-icon [style.color]="iconColor">{{ icon }}</mat-icon>
</button>
