import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { debounceTime, Observable, Subject, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AutoSaveService {
    private readonly savedSubject = new Subject<void>();
    public savedSub = this.savedSubject.asObservable();

    private readonly requestSubjects = new Map<
        FormGroup,
        { consumer: Subject<Observable<any>>; notifier: Subject<any> }
    >();

    setStorage({ id, dto }: { id: string; dto: any }): void {
        window.sessionStorage.setItem(id, JSON.stringify(dto));
    }

    getFromStorage(id: string): any {
        const item = window.sessionStorage.getItem(id);
        if (item) {
            return JSON.parse(item);
        }
        return null;
    }

    deleteFromStorage(id: string): void {
        window.sessionStorage.delete(id);
    }

    cleanUp(component: object): void {
        for (const value of Object.values(component)) {
            if (value instanceof FormGroup) {
                this.requestSubjects.delete(value);
            }
        }
    }

    /**
     *
     * @param params waitmills default 600
     * @returns
     */
    autoSaveRequest<T>(params: { identForm: FormGroup; request: Observable<T>; waitMillis?: number }): Observable<T> {
        let subjects = this.requestSubjects.get(params.identForm);

        if (!subjects) {
            const newSubjects = { consumer: new Subject<Observable<T>>(), notifier: new Subject<T>() };
            this.requestSubjects.set(params.identForm, newSubjects);
            newSubjects.consumer
                .pipe(debounceTime(typeof params.waitMillis === 'number' ? params.waitMillis : 600))
                .subscribe({
                    next: (req) => {
                        if (!this.requestSubjects.has(params.identForm)) {
                            newSubjects.notifier.complete();
                            newSubjects.consumer.complete();
                        }
                        req.subscribe((res) => {
                            if (!newSubjects.notifier.closed) {
                                newSubjects.notifier.next(res);
                            }

                            this.savedSubject.next();
                        });
                    },
                });

            subjects = newSubjects;
        }

        subjects.consumer.next(params.request);

        return subjects.notifier.asObservable().pipe(take(1));
    }
}
