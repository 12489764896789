import { Component, Inject } from '@angular/core';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AoError } from 'src/app/utils/ao-error.model';
@Component({
    selector: 'app-sheet-error',
    templateUrl: './sheet-error.component.html',
    styleUrls: ['./sheet-error.component.scss'],
})
export class SheetErrorComponent {
    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public readonly data: AoError,
        private readonly bottomSheet: MatBottomSheet,
    ) {}

    ignoreAlerts(event: MouseEvent): void {
        this.bottomSheet.dismiss(true);
        event.preventDefault();
    }
}
