import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface GoogleFont {
    name: string;
    family: string;

}

@Component({
    selector: 'app-font-picker',
    templateUrl: './font-picker.component.html',
    styleUrls: ['./font-picker.component.scss'],
})
export class FontPickerComponent implements OnInit {
    @Input() familyControl = new UntypedFormControl();


    fonts: GoogleFont[] = [
        {
            name: 'Barlow',
            family: 'Barlow, sans-serif',

        },
        {
            name: 'Fira Sans',
            family: '"Fira Sans", sans-serif',

        },
        {
            name: 'Open Sans',
            family: '"Open Sans", sans-serif',

        },
        {
            name: 'Lato',
            family: 'Lato, sans-serif',

        },
        {
            name: 'Roboto',
            family: 'Roboto, sans-serif',

        },
        {
            name: 'Archivo',
            family: 'Archivo, sans-serif',

        },
        {
            name: 'Assistant',
            family: 'Assistant, sans-serif',

        },
    ];

    font?: GoogleFont;

    ngOnInit(): void {
        if (this.familyControl.value) {
            this.font = this.fonts.find((val) => val.family === this.familyControl.value);
        }
    }
}
