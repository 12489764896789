import { NgModule } from '@angular/core';
import { SecondHeaderComponent } from './elements/second-header/second-header.component';
import { DialogConfirmComponent } from './elements/dialog-confirm/dialog-confirm.component';
import { DelayedButtonComponent } from './elements/delayed-button/delayed-button.component';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomMaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateToFullDateStringPipe } from './pipes/date-to-full-date.pipe';
import { NumberToPricePipe } from './pipes/number-to-price.pipe';
import { DelayedCloseOverlayComponent } from './elements/delayed-close-overlay/delayed-close-overlay.component';
import { RouterModule } from '@angular/router';
import { FilePickerComponent } from './elements/file-picker/file-picker.component';
import { DialogFileViewerComponent } from './elements/dialog-file-viewer/dialog-file-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatMenuModule } from '@angular/material/menu';
import { NullToStringPipe } from './pipes/null-to-string.pipe';
import { AoFilterListHeaderComponent } from './elements/ao-filter-list/ao-filter-list-header.component';
import { DialogAoMobileFilterComponent } from './elements/ao-filter-list/dialog-ao-mobile-filter/dialog-ao-mobile-filter.component';
import { DateToDateTimeStringPipe } from './pipes/date-to-date-time.pipe';
import { NgPdfApiModule } from '@ah-oh/ng-pdf-api';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { SaveBtnComponent } from './elements/save-btn/save-btn.component';
import { CustomBtnGroupComponent } from './elements/custom-btn-group/custom-btn-group.component';
import { CustomBtnComponent } from './elements/custom-btn-group/custom-btn/custom-btn.component';
import { TextEditorComponent } from './elements/text-editor/text-editor.component';
import { FontPickerComponent } from './elements/font-picker/font-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ConnectPaymentComponent } from './elements/connect-payment/connect-payment.component';
import { MatButtonLoadingDirective } from './elements/mat-button-loading.directive';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { SheetErrorComponent } from './elements/sheet-error/sheet-error.component';
import { PortalModule } from '@angular/cdk/portal';
import { DialogUpdatesAvailableComponent } from './elements/dialog-updates-available/dialog-updates-available.component';
import { DialogUnsavedChanges } from './guards/unsaved-changes.guard';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PrintDocumentDetailComponent } from './pages/settings/print-documents/print-document-detail/print-document-detail.component';
import { SecureFilePipe } from './pipes/secure-file.pipe';
import { AutoSaveComponent } from './elements/auto-save/auto-save.component';
import { DonationTypePipe } from './pipes/donation-type.pipe';
import { ColorInputComponent } from './elements/color-input/color-input.component';
import { TagChipsComponent } from './elements/tag-chips/tag-chips.component';
import { FilterSettingsComponent } from './elements/ao-filter-list/filter-settings/filter-settings.component';
import { DialogAddFilterComponent } from './elements/ao-filter-list/dialog-add-filter/dialog-add-filter.component';
import { ObjectName } from './pipes/get-object-name.pipe';
import { FilterAmountComponent } from './elements/ao-filter-list/filter-amount/filter-amount.component';
import { FilterDateRangeComponent } from './elements/ao-filter-list/filter-date-range/filter-date-range.component';
import { FilterInputComponent } from './elements/ao-filter-list/filter-input/filter-input.component';
import { FilterMultiSelectComponent } from './elements/ao-filter-list/filter-multi-select/filter-multi-select.component';
import { FilterSelectComponent } from './elements/ao-filter-list/filter-select/filter-select.component';
import { ConfirmOverlayComponent } from './elements/confirm-overlay/confirm-overlay.component';
import { UnlockComponent } from './elements/confirm-overlay/unlock/unlock.component';
import { DateToTimeSpanDaysPipe } from './pipes/date-to-time-span-days.pipe';
import { FroalaEditorModule } from 'angular-froala-wysiwyg';
import { PdfJobsComponent } from './elements/navbar/pdf-jobs/pdf-jobs.component';
import { DateToTimePipe } from './pipes/date-to-time.pipe';
import { PdfProgressComponent } from './elements/navbar/pdf-jobs/pdf-progress/pdf-progress.component';
import { DialogDownloadPdfComponent } from './elements/dialog-download-pdf/dialog-download-pdf.component';
import { DownloadBadgeComponent } from './elements/navbar/download-badge/download-badge.component';
import { AmountControlComponent } from './elements/amount-control/amount-control.component';
import { BirthdayToAgePipe } from './pipes/birthday-to-age.pipe';
import { DonatorAuthNrPipe } from './pipes/donator-auth-nr.pipe';
import { DonatorInfoComponent } from './elements/donator-info/donator-info.component';
import { DonatorNamePipe } from './pipes/donator-name.pipe';
import { DonationEntryDonatorNamePipe } from './pipes/donation-entry-donator-name.pipe';

@NgModule({
    declarations: [
        SecondHeaderComponent,
        AutoSaveComponent,
        FilterSettingsComponent,
        DialogAddFilterComponent,
        DelayedCloseOverlayComponent,
        DialogConfirmComponent,
        DelayedButtonComponent,
        DateToFullDateStringPipe,
        DateToDateTimeStringPipe,
        NumberToPricePipe,
        NullToStringPipe,
        DonationTypePipe,
        DonatorNamePipe,
        DonationEntryDonatorNamePipe,
        ObjectName,
        SecureFilePipe,
        DateToTimeSpanDaysPipe,
        DateToTimePipe,
        BirthdayToAgePipe,
        DonatorAuthNrPipe,
        FilePickerComponent,
        DialogFileViewerComponent,
        AoFilterListHeaderComponent,
        DialogAoMobileFilterComponent,
        SaveBtnComponent,
        CustomBtnGroupComponent,
        CustomBtnComponent,
        TextEditorComponent,
        ColorInputComponent,
        FontPickerComponent,
        ConnectPaymentComponent,
        MatButtonLoadingDirective,
        SheetErrorComponent,
        DialogUpdatesAvailableComponent,
        DialogUnsavedChanges,
        PrintDocumentDetailComponent,
        TagChipsComponent,
        FilterAmountComponent,
        FilterDateRangeComponent,
        FilterInputComponent,
        FilterMultiSelectComponent,
        FilterSettingsComponent,
        FilterSelectComponent,
        ConfirmOverlayComponent,
        UnlockComponent,
        PdfJobsComponent,
        PdfProgressComponent,
        DialogDownloadPdfComponent,
        DownloadBadgeComponent,
        AmountControlComponent,
        DonatorInfoComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        FlexLayoutModule,
        CustomMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        PdfViewerModule,
        MatMenuModule,
        NgPdfApiModule,
        HttpClientJsonpModule,
        HttpClientModule,
        ColorPickerModule,
        MatButtonToggleModule,
        MatBottomSheetModule,
        PortalModule,
        InfiniteScrollModule,
        FroalaEditorModule,
    ],
    exports: [
        SecondHeaderComponent,
        AutoSaveComponent,
        FilterSettingsComponent,
        DialogAddFilterComponent,
        DialogConfirmComponent,
        DelayedCloseOverlayComponent,
        FlexLayoutModule,
        CustomMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        DelayedButtonComponent,
        DateToFullDateStringPipe,
        DateToDateTimeStringPipe,
        NumberToPricePipe,
        NullToStringPipe,
        DonatorNamePipe,
        DonationEntryDonatorNamePipe,
        DonationTypePipe,
        BirthdayToAgePipe,
        ObjectName,
        DonatorAuthNrPipe,
        DateToTimePipe,
        SecureFilePipe,
        DateToTimeSpanDaysPipe,
        FilePickerComponent,
        DialogFileViewerComponent,
        PdfViewerModule,
        MatMenuModule,
        AoFilterListHeaderComponent,
        DialogAoMobileFilterComponent,
        NgPdfApiModule,
        SaveBtnComponent,
        CustomBtnGroupComponent,
        CustomBtnComponent,
        TextEditorComponent,
        ColorPickerModule,
        ColorInputComponent,
        FontPickerComponent,
        ConnectPaymentComponent,
        MatButtonLoadingDirective,
        MatButtonToggleModule,
        InfiniteScrollModule,
        MatBottomSheetModule,
        SheetErrorComponent,
        DialogUpdatesAvailableComponent,
        PortalModule,
        DialogUnsavedChanges,
        PrintDocumentDetailComponent,
        TagChipsComponent,
        FilterAmountComponent,
        FilterDateRangeComponent,
        FilterInputComponent,
        FilterMultiSelectComponent,
        FilterSettingsComponent,
        FilterSelectComponent,
        ConfirmOverlayComponent,
        UnlockComponent,
        PdfJobsComponent,
        PdfProgressComponent,
        DialogDownloadPdfComponent,
        DownloadBadgeComponent,
        AmountControlComponent,
        DonatorInfoComponent,
    ],
    providers: [
        DateToFullDateStringPipe,
        NumberToPricePipe,
        DateToDateTimeStringPipe,
        DateToTimeSpanDaysPipe,
        SecureFilePipe,
        NullToStringPipe,
        DonationTypePipe,
        DonatorNamePipe,
        DonationEntryDonatorNamePipe,
    ],
})
export class GlobalModule {}
