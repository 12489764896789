import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, startWith, distinctUntilChanged, switchMap, map } from 'rxjs';
import { Tag } from 'src/app/models/tags.model';
import { TagsService } from 'src/app/services/tags.service';

@Component({
    selector: 'app-tag-chips',
    templateUrl: './tag-chips.component.html',
    styleUrls: ['./tag-chips.component.scss'],
})
export class TagChipsComponent implements OnInit {
    @Input() tags: Tag[] | undefined = [];
    @Input() formCtrl!: UntypedFormControl;

    @ViewChild('input', { static: false }) input?: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: false }) matAutocomplete?: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger, { static: false }) trigger?: MatAutocompleteTrigger;

    control = new UntypedFormControl();
    filteredTags?: Observable<Tag[]>;

    constructor(private readonly tagsService: TagsService) {}

    ngOnInit() {
        this.filteredTags = this.control.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            switchMap((val: string | null) => {
                return val && typeof val === 'string'
                    ? this.tagsService
                          .find({ search: val })
                          .pipe(
                              map((res) => res.filter((tag) => this.tags?.findIndex((t) => t._id === tag._id) === -1)),
                          )
                    : [];
            }),
        );
    }

    removeTag(tag: Tag): void {
        const index = this.formCtrl.value?.findIndex((t: Tag) => t._id === tag._id);

        if (index || index === 0) {
            const value = this.formCtrl.value.slice();
            value.splice(index, 1);
            this.formCtrl.setValue(value);
        }
    }

    selectTag(event: MatAutocompleteSelectedEvent): void {
        const tag: Tag = event.option.value;

        const value = this.formCtrl.value;
        value.push(tag);
        this.formCtrl.setValue(value);

        if (this.input) {
            this.input.nativeElement.value = '';
        }
        this.control.setValue(null);
    }
}
