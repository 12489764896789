import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from './loader.service';
import { finalize, tap } from 'rxjs/operators';
import { AoAuthService } from '@ah-oh/company-auth';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';
import { AoError } from '../utils/ao-error.model';
import { SheetErrorComponent } from '../elements/sheet-error/sheet-error.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private ignoreAlerts = false;

    constructor(
        private readonly router: Router,
        private readonly authService: AoAuthService,
        private readonly loaderService: LoaderService,
        private readonly snackBar: MatSnackBar,
        private readonly bottomSheet: MatBottomSheet,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loaderService.show();

        let headers = req.headers;

        if (!headers.get('Authorization')) {
            if (this.authService.signedInRes?.token) {
                headers = headers.append('Authorization', this.authService.signedInRes.token);
            } else if (!environment.production) {
                const token = localStorage.getItem('dev-token');
                if (token) {
                    headers = headers.append('Authorization', token);
                }
            }
        }

        let params = req.params;
        if (params.has('ignoreAlerts') && this.ignoreAlerts) {
            params = params.delete('ignoreAlerts');
            params = params.append('ignoreAlerts', String(this.ignoreAlerts));
            this.ignoreAlerts = false;
        }

        const clonedReq = req.clone({
            headers,
            params,
            withCredentials: true,
        });

        return next.handle(clonedReq).pipe(
            tap(
                () => {
                    // was successful
                },
                (err) => {
                    if (err.status === 401) {
                        if (this.authService.isSignedIn) {
                            this.authService.removeSignIn();
                            this.router.navigateByUrl('/auth');
                        }
                    } else if (err.status === 500) {
                        this.snackBar.open('Synchronisationsfehler!', '', {
                            duration: 2000,
                        });
                    } else if (err.status === 400) {
                        const error = <AoError>err.error;
                        if (error.type === 'error' && !error.fields?.length) {
                            this.bottomSheet.open(SheetErrorComponent, {
                                data: error,
                                panelClass: 'error-panel',
                            });
                        } else if (error.type === 'alert' && !error.fields?.length) {
                            const ignorePermission = false; // TODO
                            const sheet = this.bottomSheet.open(SheetErrorComponent, {
                                data: Object.assign({ ignorePermission }, error),
                                panelClass: 'warning-panel',
                            });
                            sheet.afterDismissed().subscribe((res) => {
                                this.ignoreAlerts = res;
                            });
                        }
                    }
                },
            ),
            finalize(() => {
                this.loaderService.hide();
            }),
        );
    }
}
