import { Pipe, PipeTransform } from '@angular/core';
import { DonationEntry } from '../models/donation-entry-list.interface';
import { DonatorFormatUtil } from '../utils/donator-format.util';

@Pipe({
    name: 'donationEntryDonatorName',
})
export class DonationEntryDonatorNamePipe implements PipeTransform {
    transform(entry: DonationEntry | string | undefined): string {
        return DonatorFormatUtil.formatDonationEntryName(entry);
    }
}
