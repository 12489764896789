<div class="field-container">
    <div [matMenuTriggerFor]="menu" [class.active]="control.value?.length || field.sortState" class="field-name">
        <div class="placeholder" [class.active]="hasValue">{{ field.placeholder }}</div>
        <div class="value-text" *ngIf="hasValue">
            <div *ngIf="hasValue">{{ valueText }}</div>
        </div>
        <span *ngIf="control.value?.length">{{ control.value?.length }}</span>
    </div>

    <mat-menu class="filter-menu" #menu="matMenu">
        <div class="field-content" (click)="$event.stopPropagation()">
            <div class="heading">{{ field.placeholder }}</div>

            <div class="icons" fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                    <span *ngIf="field.sortKey" [class.active]="field.sortState">
                        <mat-icon
                            (click)="sortChange.emit()"
                            class="sort-icon"
                            [class.active]="field.sortState"
                            [class.rotate]="field.sortState === 'desc'"
                            >north</mat-icon
                        >
                    </span>
                </div>
                <mat-icon (click)="clear()">highlight_off</mat-icon>
            </div>

            <div *ngIf="formSet" [formGroup]="form">
                <mat-checkbox
                    *ngFor="let option of field.selectValues"
                    [value]="option.value"
                    class="checkbox-block wrap-box"
                    color="primary"
                    [formControlName]="option.value"
                >
                    {{ option.name }}
                </mat-checkbox>
            </div>
        </div>
    </mat-menu>
</div>
