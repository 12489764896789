import { FindEvaluationDonationsDto } from '../dtos/donations/find-evaluation-donations.dto';

export enum PermissionSection {
    DONATION_ENTRANCE = 'donator-entrance',
    DONATORS = 'donators',
    PROJECTS = 'projects',
    EVALUATION = 'evaluation',
    SETTINGS = 'settings',
    CHANNEL_IMPORT = 'channel-import',
    RECEIPTS = 'receipts',
    COLLECTION_LETTERS = 'collection-letters',
}

export interface User extends Document {
    _id: string;
    authId: string;
    fcmClientId: string;
    archived: boolean;
    company: string;
    permissions: PermissionSection[];
    evaluationFilters: EvaluationFilter[];

    createdAt: Date;
    updatedAt: Date;
}
export interface EvaluationFilter {
    _id: string;
    name: string;
    filter: FindEvaluationDonationsDto;
}
