import { Injectable } from '@angular/core';
import { TypeForm } from 'src/app/utils/type-form';

@Injectable({
    providedIn: 'root',
})
export class DonatorFormValidationService {
    setValidation(form: TypeForm, emit = true): void {
        const isOrganization = form.getFormControl('mode').value === 'company';
        const unknownAddress = form.getFormControl('unknownAddress').value;

        form.disableControls(unknownAddress, 'street', 'zipCode', 'city', 'country', 'addressAddition');

        form.setRequired({ required: !isOrganization, emit, names: ['firstName', 'lastName'] });
        form.setRequired({ required: isOrganization, emit, names: ['companyName'] });
        form.setRequired({ required: !unknownAddress, emit, names: ['street', 'zipCode', 'city'] });

        if (unknownAddress) {
            form.patchValue({ street: '', zipCode: '', city: '', country: '', addressAddition: '' });
        }
    }
}
