import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'objectName',
})
export class ObjectName implements PipeTransform {
    transform(value?: { name: string } | string): unknown {
        if (typeof value === 'string') {
            return ' -';
        } else {
            return value ? value.name : ' -';
        }
    }
}
