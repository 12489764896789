import { Donator } from './donator.interface';
import { ImportChannel } from '../services/import-channels/import-channel.model';
import { S3File } from './s3-file.interface';
import { Project } from './projects/project.interface';
import { Transaction } from '@ah-oh/fin-api';
import { DonatorDto } from '../dtos/donators/donator.dto';

export enum DonationType {
    MONEY = 'money',
    MATERIAL = 'material',
    ENDOWMENT = 'endowment',
    EXPENSE_WAIVER = 'expense-waiver',
}

export enum DonationPaymentStatus {
    PENDING = 'pending',
    PAID = 'paid',
}

export interface Donation {
    _id: string;
    donationId: string;
    channel?: ImportChannel | string;
    amount: number;
    date: Date;
    type: DonationType;
    node: string;
    permanentDonation: boolean;
    receipt?: Date;
    needReceipt: boolean;
    /** DonationChannel Altruja */
    temporaryDonator?: DonatorDto;
    /** DonationChannel Finapi */
    finTransaction?: Transaction;
    archived: boolean;
    paymentStatus: DonationPaymentStatus;
    printed: boolean;
    files: S3File[];
    hasAnonymousDonator?: boolean;

    purposeBinding: boolean;
    /** purposeBinding = true */
    purposeName?: string;

    pdfJobId?: string;

    /** type MATERIAL */
    companyMaterial?: boolean;
    privateMaterial?: boolean;
    unknownMaterial?: boolean;
    materialFiles?: boolean;

    /** type EXPENSE_WAIVER  */
    assetStock?: boolean;
    notConsumable?: boolean;

    donator?: Donator | string;
    company: string;

    project: Project | string;

    createdAt: Date;
    updatedAt: Date;
}
