import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nullToString',
})
export class NullToStringPipe implements PipeTransform {
    transform(str: null | undefined | string): string {
        return str !== undefined && str !== null && str.length ? String(str) : '-';
    }
}
