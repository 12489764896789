import { PdfJob } from '@ah-oh/ng-pdf-api';
import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
dayjs.locale('de');

@Component({
    selector: 'app-pdf-jobs',
    templateUrl: './pdf-jobs.component.html',
    styleUrls: ['./pdf-jobs.component.scss'],
})
export class PdfJobsComponent {
    private _pdfJobs: PdfJob[] = [];
    get pdfJobs(): PdfJob[] {
        return this._pdfJobs;
    }
    @Input() set pdfJobs(value: PdfJob[]) {
        this._pdfJobs = value;
        this.setJobs();
    }

    @Output() delete = new EventEmitter<PdfJob>();
    @Output() download = new EventEmitter<PdfJob>();

    jobMap: Map<string, PdfJob[]> = new Map();

    sort = (a: KeyValue<string, PdfJob[]>, b: KeyValue<string, PdfJob[]>): number => {
        return dayjs(a.value[0].createdAt).isBefore(b.value[0].createdAt) ? 1 : -1;
    };

    setJobs(): void {
        this.jobMap = new Map();

        for (const job of this.pdfJobs) {
            let day = dayjs(job.createdAt).format('DD MMMM YYYY');
            if (dayjs().isSame(dayjs(job.createdAt), 'date')) {
                day = 'Heute';
            }

            const jobs = this.jobMap.get(day) ?? [];
            jobs.push(job);
            this.jobMap.set(
                day,
                jobs.sort((a, b) => (dayjs(a.createdAt).isBefore(b.createdAt) ? 1 : -1)),
            );
        }
    }
}
