import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateToFullDateString',
})
export class DateToFullDateStringPipe implements PipeTransform {
    transform(dateStr: any): string {
        const timestamp: number = Date.parse(dateStr);
        if (Number.isNaN(timestamp)) {
            return '';
        }
        const date = new Date(timestamp);

        return this.addZero(date.getDate()) + '.' + this.addZero(date.getMonth() + 1) + '.' + date.getFullYear();
    }

    private addZero(num: number): string {
        const strNum = String(num);
        return strNum.length === 1 ? '0' + strNum : strNum;
    }
}
