import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import { DonatorFormValidationService } from 'src/app/pages/donations-manual-assign/manual-assign/donators/donator-detail/donator-settings/donator-form-validation.service';
import { AutoSaveService } from 'src/app/services/auto-save.service';
import { EmailService } from 'src/app/services/email/email.service';
import { TypeForm } from 'src/app/utils/type-form';

export enum ValidationType {
    DONATOR = 'donator',
    MAIL = 'mail',
}

@Component({
    selector: 'app-auto-save',
    templateUrl: './auto-save.component.html',
    styleUrls: ['./auto-save.component.scss'],
})
export class AutoSaveComponent implements OnInit, OnDestroy {
    private _form!: TypeForm;
    get form(): TypeForm {
        return this._form;
    }
    @Input() set form(value: TypeForm) {
        this._form = value;

        this.setData();
    }

    @Input() blackList: string[] = [];

    @Input() validate = false;
    @Input() validationType?: ValidationType;
    @Input() ignoreInvalid = false;
    @Input() preventSave = false;

    private _showChange = true;
    get showChange() {
        return this._showChange;
    }
    @Input() set showChange(value) {
        this._showChange = value;

        if (!this.showChange) {
            this.hasChanges = false;
        }
    }

    hasChanges = false;
    wasReset = false;
    resetSaved = 0;
    storageSet = 1;
    lastSaved = '';
    lastUpdated = '';

    private readonly formChanged = new Subject<void>();
    private readonly destroy = new Subject<void>();

    constructor(
        private readonly autoSaveService: AutoSaveService,
        private readonly donatorFormValidation: DonatorFormValidationService,
        private readonly emailService: EmailService,
    ) {}

    ngOnInit(): void {
        // called when reloading the page
        fromEvent(window, 'beforeunload')
            .pipe(takeUntil(this.destroy))
            .subscribe((e: BeforeUnloadEvent) => {
                // check if input element is in focus -> if true: there are unsaved changes -> get warning
                if (document.activeElement?.tagName.toLowerCase() === 'input') {
                    e.preventDefault();
                    e.returnValue = '';
                }
            });
    }

    ngOnDestroy(): void {
        this.formChanged.next();
        this.formChanged.complete();
        this.destroy.next();
        this.destroy.complete();
    }

    private setData(): void {
        const id = this.form.getFormControl('_id').value;

        if (id) {
            this.autoSaveService.setStorage({ id: id + '-old', dto: this.form.createDto(false) });
            this.autoSaveService.setStorage({ id: id + '-current', dto: this.form.createDto(false) });
        }

        const updated = this.form.getFormControl('updatedAt').value;

        if (updated) {
            let minutes = dayjs().diff(dayjs(updated), 'minute');

            if (minutes <= 0) {
                this.lastUpdated = 'gerade eben';
            } else {
                let hours = 0;
                let days = 0;

                if (minutes >= 60) {
                    hours = Math.floor(minutes / 60);
                    minutes -= hours * 60;
                }
                if (hours >= 24) {
                    days = Math.floor(hours / 24);
                    hours -= days * 24;
                }

                this.lastUpdated = ` vor ${days ? days + ' Tage' : ''} ${hours ? hours + ' Stunden' : ''} ${
                    minutes ? minutes + ' Minuten' : ''
                }`;
            }
        }

        this.formChanged.next();

        this.autoSaveService.savedSub.pipe(takeUntil(this.formChanged)).subscribe(() => {
            this.onSaved();
        });

        this.form.valueChanges.pipe(takeUntil(this.formChanged)).subscribe((v) => {
            if ((!this.ignoreInvalid && this.form.invalid) || this.preventSave) {
                return;
            }

            this.lastUpdated = '';

            if (this.showChange) {
                this.hasChanges = true;
            }
        });
    }

    onSaved(): void {
        this.lastSaved = dayjs().format('HH:mm');
        this.hasChanges = false;

        if (this.resetSaved !== 0) {
            this.resetSaved--;
        } else {
            this.wasReset = false;
        }

        this.setStorage();
    }

    private setStorage(): void {
        const id = this.form.getFormControl('_id').value;

        if (id) {
            const current = this.autoSaveService.getFromStorage(id + '-current');

            if (current) {
                this.autoSaveService.setStorage({ id: id + '-old', dto: current });
            }

            this.autoSaveService.setStorage({ id: id + '-current', dto: this.form.createDto(false) });
        }
    }

    reset(): void {
        const id = this.form.getFormControl('_id').value;

        if (id) {
            const dto = this.autoSaveService.getFromStorage(id + '-old');

            if (dto) {
                const dateFieldNames = ['date', 'birthday'];

                this.form.setValues({ values: dto, emit: true, blackList: this.blackList.concat(dateFieldNames) });

                for (const fieldName of dateFieldNames) {
                    if (fieldName in dto) {
                        const lastValue = dto[fieldName] ? dayjs(dto[fieldName]).format('DD.MM.YYYY') : null;
                        this.form.getFormControl(fieldName).setValue(lastValue, { emitEvent: false });
                    }
                }

                this.resetSaved = 1;
                this.wasReset = true;

                if (this.validate) {
                    switch (this.validationType) {
                        case ValidationType.DONATOR:
                            this.donatorFormValidation.setValidation(this.form);
                            break;
                        case ValidationType.MAIL:
                            this.form
                                .getFormControl('customSMTP')
                                .setValue(dto['customSMTP'] === 'true', { emitEvent: false });

                            this.emailService.setValidation(this.form);

                            break;

                        default:
                            break;
                    }
                }
            }
        }
    }
}
