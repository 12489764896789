import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { timer } from 'rxjs';

export interface DialogConfirmOverlayData {
    headline: string;
    content: string;
    confirmText: string;
    lockedBackgroundColor?: string;
    unlockedBackgroundColor?: string;
    info?: string;
    buttonText?: string;
}

@Component({
    selector: 'app-confirm-overlay',
    templateUrl: './confirm-overlay.component.html',
    styleUrls: ['./confirm-overlay.component.scss'],
})
export class ConfirmOverlayComponent {
    @Output() locked = new EventEmitter();
    @Output() unlocked = new EventEmitter();

    disabled = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: DialogConfirmOverlayData,
        public readonly dialogRef: MatDialogRef<ConfirmOverlayComponent>,
    ) {}

    close(value: boolean): void {
        if (value) {
            this.disabled = true;
            timer(1000).subscribe(() => {
                this.dialogRef.close(value);
            });
        } else {
            this.dialogRef.close(value);
        }
    }
}
