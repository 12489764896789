import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil, timer } from 'rxjs';

@Component({
    selector: 'app-amount-control',
    templateUrl: './amount-control.component.html',
    styleUrls: ['./amount-control.component.scss'],
})
export class AmountControlComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('input') input?: ElementRef;

    private _control!: FormControl;
    get control(): FormControl {
        return this._control;
    }
    @Input() set control(value: FormControl) {
        this._control = value;
        this.setValue();
    }

    @Input() label = 'Betrag';

    private _disabled = false;
    get disabled() {
        return this._disabled;
    }
    @Input() set disabled(value) {
        this._disabled = value;

        if (this.disabled) {
            this.amount.disable({ emitEvent: false });
        } else {
            this.amount.enable({ emitEvent: false });
        }
    }

    amount = new FormControl('', { updateOn: 'blur' });

    result = '';

    private readonly destroy = new Subject<void>();

    ngOnInit(): void {
        this.amount.valueChanges.pipe(takeUntil(this.destroy)).subscribe((val) => {
            this.validateAmount(val ?? '');
            this.setAmount();
        });
    }

    ngAfterViewInit(): void {
        timer(0).subscribe(() => {
            this.setValue();
        });

        this.control.valueChanges.pipe(takeUntil(this.destroy)).subscribe((val) => {
            if (String(val) !== this.result && this.input) {
                this.validateAmount(String(val).replace('.', ','));
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    setValue(): void {
        if (this.control.value !== null && this.control.value !== undefined) {
            this.validateAmount(String(this.control.value).replace('.', ','));
        }
    }

    validateAmount(value: string) {
        const val = value.replace(new RegExp('[^0-9,]', 'g'), '');
        let result = '';

        const hasComma = val.indexOf(',') !== -1;
        const euro = val.slice(0, hasComma ? val.indexOf(',') : val.length);
        let cent = hasComma ? val.slice(val.indexOf(',') + 1, val.length) : '';
        cent = cent.replace(new RegExp(',', 'g'), '');

        let count = 0;
        for (let index = euro.length - 1; index > -1; index--) {
            const v = euro[index];

            if (count === 3) {
                result = '.' + result;
                count = 0;
            }

            result = v + result;
            count++;
        }

        if (hasComma) {
            if (cent.length == 1) {
                cent += '0';
            }

            result += ',' + cent.slice(0, 2);
        } else {
            result += ',00';
        }

        if (result[0] === '0' && result[1] && result[1] != ',' && result[1] != ' ') {
            result = result.slice(result[1] === '.' ? 2 : 1, result.length);
        }

        if (this.input) {
            this.result = result;
            this.input.nativeElement.value = result += ' €';
            this.input.nativeElement.selectionStart = this.input.nativeElement.value.length - 2;
            this.input.nativeElement.selectionEnd = this.input.nativeElement.selectionStart;
        }
    }

    setAmount() {
        if (this.input) {
            let value = this.input.nativeElement.value;

            value = value.replace(new RegExp('\\.', 'g'), '');
            value = value.replace(new RegExp(',', 'g'), '.');
            value = value.replace(new RegExp('[^0-9.]', 'g'), '');
            value = Number(value).toFixed(2);

            if (this.control.value !== value) {
                this.control.setValue(value);
            }
        }
    }
}
