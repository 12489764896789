<div class="document-preview">
    <app-second-header *ngIf="!id" [headline]="headline" [searchBar]="false">
        <button mat-stroked-button color="primary" routerLink="/einstellungen/dokumente">Abbrechen</button>
        <app-save-btn (save)="onSubmit()" [hasChanges]="hasChanges"></app-save-btn>
    </app-second-header>

    <div *ngIf="!id" class="ao-card big">
        <form [formGroup]="form">
            <div fxLayout="row">
                <div fxFlex="33.333333" fxFlex.lt-sm="100">
                    <div class="body">
                        <div class="headline">
                            <h2>Dokument</h2>
                        </div>

                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Titel</mat-label>
                            <input type="text" matInput formControlName="name" maxlength="256" />
                            <mat-error *ngIf="form.get('name')?.getError('required')"> Pflichtfeld </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <lib-drive-item-preview
        *ngIf="pdfApiClient"
        [client]="pdfApiClient"
        [(driveItem)]="file"
        (driveItemChange)="hasChanges = true; driveItemChange.emit()"
    ></lib-drive-item-preview>
</div>
