import {
    AbstractControl,
    AbstractControlOptions,
    AsyncValidatorFn,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import dayjs from 'dayjs';

export enum ControlType {
    INT = 'int',
    FLOAT = 'float',
    STRING = 'str',
    BOOL = 'boolean',
    DATE = 'date',
    DATE_STRING = 'dateString',
    TIMESTAMP = 'timestamp',
    MOMENT = 'dayjs',
    MOMENT_RANGE = 'dayjsRange',
    ARR = 'arr',
    OBJECT = 'obj',
    FORM = 'form',
    FORM_ARR = 'formArr',
    CHIPS = 'chips',
}

export class TypeForm extends UntypedFormGroup {
    typeControls: {
        [key: string]: {
            control: AbstractControl;
            type: ControlType;
            default?: any;
            onlyPresentation?: boolean;
        };
    };

    constructor(
        typeControls: {
            [key: string]: {
                control: AbstractControl;
                type: ControlType;
                default?: any;
                onlyPresentation?: boolean;
            };
        },
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    ) {
        const controls: {
            [key: string]: AbstractControl;
        } = {};
        for (const [key, value] of Object.entries(typeControls)) {
            controls[key] = value.control;
        }
        super(controls, validatorOrOpts, asyncValidator);
        this.typeControls = typeControls;

        // Set default values
        for (const value of Object.values(typeControls)) {
            if (
                Object.prototype.hasOwnProperty.call(typeControls, 'default') &&
                !(value.control instanceof UntypedFormArray)
            ) {
                if (value.control instanceof TypeForm) {
                    value.control.setValues({ values: value.default });
                } else {
                    value.control.setValue(value.default, { emitEvent: false });
                }
            }
        }
    }

    getTypedValue(key: string): any {
        const typeControl = this.typeControls[key];
        const val = typeControl.control.value;
        const defaultValue = typeControl.default;

        if (val === null || val === undefined || String(val).length === 0) {
            return defaultValue;
        }

        switch (typeControl.type) {
            case ControlType.INT: {
                const num = Number(val);
                return Number.isNaN(num) ? defaultValue : Math.round(num);
            }
            case ControlType.FLOAT: {
                const num: number = Number.parseFloat(val);
                return Number.isNaN(num) ? defaultValue : num;
            }
            case ControlType.STRING: {
                return String(val);
            }
            case ControlType.BOOL: {
                return val === true || val === 'true';
            }
            case ControlType.DATE: {
                const date = dayjs(val, 'DD.MM.YYYY');

                return date.isValid() ? date.toISOString() : defaultValue;
            }
            case ControlType.DATE_STRING: {
                const timestamp: number = Date.parse(val);
                return Number.isNaN(timestamp) ? defaultValue : new Date(timestamp).toISOString();
            }
            case ControlType.TIMESTAMP: {
                const timestamp: number = Date.parse(val);
                return Number.isNaN(timestamp) ? defaultValue : timestamp;
            }

            case ControlType.ARR: {
                return Array.isArray(val) ? val : defaultValue;
            }
            case ControlType.OBJECT: {
                return typeof val === 'object' ? val : defaultValue;
            }
            case ControlType.FORM: {
                if (typeControl.control instanceof TypeForm) {
                    return typeControl.control.createDto(false);
                }
                return defaultValue;
            }
            case ControlType.FORM_ARR: {
                const result: any = [];
                if (typeControl.control instanceof UntypedFormArray) {
                    for (const control of typeControl.control.controls) {
                        if (control instanceof TypeForm) {
                            result.push(control.createDto(false));
                        }
                    }
                }
                return result.length ? result : defaultValue;
            }
            case ControlType.CHIPS: {
                return Array.isArray(val) ? val.map((v) => v._id) : defaultValue;
            }
        }
    }

    setValues({ values, emit = false, blackList = [] }: { values: any; emit?: boolean; blackList?: string[] }): void {
        if (values) {
            for (const [key, control] of Object.entries(this.controls)) {
                const valueToSet = values[key];

                if (valueToSet !== undefined && valueToSet !== null && blackList.indexOf(key) === -1) {
                    if (control instanceof TypeForm) {
                        control.setValues({ values: valueToSet });
                    } else if (control instanceof UntypedFormArray) {
                        if (Array.isArray(valueToSet) && valueToSet.length === control.controls.length) {
                            control.controls.forEach((element, index) => {
                                if (element instanceof TypeForm) {
                                    element.setValues({ values: valueToSet[index] });
                                }
                            });
                        } else {
                            control.clear({ emitEvent: emit });
                        }
                    } else {
                        this.get(key)?.setValue(valueToSet, {
                            emitEvent: emit,
                        });
                    }
                }
            }
        }
    }

    setDirty(): void {
        Object.keys(this.controls).forEach((key) => {
            this.get(key)?.markAsTouched();
        });
    }

    createDto<T>(onlyDirty: boolean): T {
        const result: any = {};
        for (const [key, value] of Object.entries(this.typeControls)) {
            if (!value.onlyPresentation && (!onlyDirty || this.get(key)?.dirty)) {
                result[key] = this.getTypedValue(key);
            }
        }
        return result;
    }

    getFormControl(name: string): UntypedFormControl {
        const control = this.get(name);
        if (control instanceof UntypedFormControl) {
            return control;
        }
        throw new Error('No FormControl (' + name + ')');
    }

    getForm(name: string): TypeForm {
        const control = this.get(name);
        if (control instanceof TypeForm) {
            return control;
        }
        throw new Error('No TypeForm');
    }

    getFormArray(name: string): TypeForm[] {
        const control = this.get(name);
        if (control instanceof UntypedFormArray) {
            return <TypeForm[]>control.controls;
        }
        throw new Error('No FormArray (' + name + ')');
    }

    getRawFormArray(name: string): UntypedFormArray {
        const control = this.get(name);
        if (control instanceof UntypedFormArray) {
            return control;
        }
        throw new Error('No FormArray (' + name + ')');
    }

    setRequired({ required, emit = true, names = [] }: { required: boolean; emit: boolean; names: string[] }): void {
        for (const name of names) {
            this.getFormControl(name).setValidators(required ? [Validators.required] : []);

            if (emit) {
                this.getFormControl(name).updateValueAndValidity();
            }
        }
    }

    disableControls(disable = true, ...names: string[]): void {
        for (const name of names) {
            if (disable) {
                this.getFormControl(name).disable({ emitEvent: false });
            } else {
                this.getFormControl(name).enable({ emitEvent: false });
            }
        }
    }
}
