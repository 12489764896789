import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { ControlType, TypeForm } from 'src/app/utils/type-form';
import { AoFilterHeaderField } from '../ao-filter-list-header.component';

@Component({
    selector: 'app-filter-amount',
    templateUrl: './filter-amount.component.html',
    styleUrls: ['./filter-amount.component.scss'],
})
export class FilterAmountComponent implements OnInit, OnDestroy {
    @Input() field!: AoFilterHeaderField;
    @Input() form!: UntypedFormGroup;

    private _filter!: any;
    get filter(): any {
        return this._filter;
    }
    @Input() set filter(value: any) {
        this._filter = value;
        this.setValues();
    }

    @Output() setAmount = new EventEmitter<void>();
    @Output() sortChange = new EventEmitter<void>();

    public hasValue = false;
    amount = new TypeForm({
        min: {
            type: ControlType.FLOAT,
            control: new UntypedFormControl(),
        },
        max: {
            type: ControlType.FLOAT,
            control: new UntypedFormControl(),
        },
    });

    private readonly destroy = new Subject<void>();

    ngOnInit(): void {
        this.setValues();
        this.hasValue = this.amount.value.min != null || this.amount.value.max != null;

        this.amount.valueChanges.pipe(takeUntil(this.destroy)).subscribe((value) => {
            this.form.get(this.field.name)?.setValue(value);
            this.hasValue = value.min != null || value.max != null;
        });
    }

    setValues(): void {
        const val = this.filter[this.field.name];

        const min = this.amount.getFormControl('min');
        const max = this.amount.getFormControl('max');

        if (!val || val['min'] !== min.value) {
            min.setValue(val ? val['min'] : null, { emitEvent: false });
        }

        if (!val || val['max'] !== max.value) {
            max.setValue(val ? val['max'] : null, { emitEvent: false });
        }
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    clear(): void {
        this.amount.reset();
    }
}
