import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {
    itemsPerPageLabel = 'Elemente pro Seite:';
    nextPageLabel = 'Nächste Seite';
    previousPageLabel = 'Zurück';
    lastPageLabel = 'Letzte Seite';
    firstPageLabel = 'Erste Seite';
    // eslint-disable-next-line max-params-no-constructor/max-params-no-constructor
    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 / ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} / ${length}`;
    };
}
