<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="field-container">
    <div [matMenuTriggerFor]="menu" class="field-name" [class.active]="hasValue || field.sortState">
        <div class="placeholder" [class.active]="hasValue">{{ field.placeholder }}</div>
        <div class="value-text" *ngIf="hasValue">
            <div *ngIf="amount.getFormControl('min').value != null">von {{ amount.getFormControl('min').value }}</div>
            <div *ngIf="amount.getFormControl('max').value != null">bis {{ amount.getFormControl('max').value }}</div>
        </div>
    </div>

    <mat-menu class="filter-menu" #menu="matMenu">
        <div class="field-content" (click)="$event.stopPropagation()">
            <div class="heading">{{ field.placeholder }}</div>

            <div class="icons" fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                    <span *ngIf="field.sortKey" [class.active]="field.sortState">
                        <mat-icon
                            (click)="sortChange.emit()"
                            class="sort-icon"
                            [class.active]="field.sortState"
                            [class.rotate]="field.sortState === 'desc'"
                            >north</mat-icon
                        >
                    </span>
                </div>
                <mat-icon (click)="clear()">highlight_off</mat-icon>
            </div>

            <input class="input" type="number" [placeholder]="'Von'" [formControl]="amount.getFormControl('min')" />
            <input class="input" type="number" [placeholder]="'Bis'" [formControl]="amount.getFormControl('max')" />
        </div>
    </mat-menu>
</div>
