<div class="badge">
    <div *ngIf="notDownloaded && !isProcessing && pdfMenuSeen" class="circle">
        <span>{{ notDownloaded < 10 ? notDownloaded : '9+' }}</span>
    </div>

    <div *ngIf="isProcessing" class="circle">
        <div class="hold left">
            <div class="fill"></div>
        </div>
        <div class="hold right">
            <div class="fill"></div>
        </div>
    </div>

    <div *ngIf="!isProcessing && !pdfMenuSeen" class="circle attention"><span>!</span></div>
</div>
