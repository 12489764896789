import { PdfJob, PdfJobStatus } from '@ah-oh/ng-pdf-api';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-pdf-progress',
    templateUrl: './pdf-progress.component.html',
    styleUrls: ['./pdf-progress.component.scss'],
})
export class PdfProgressComponent implements OnInit, OnDestroy {
    @Input() job!: PdfJob;

    progress = 0;

    private readonly destroy = new Subject<void>();

    ngOnInit(): void {
        if (this.job.status === PdfJobStatus.PROCESSING) {
            interval(1000)
                .pipe(takeUntil(this.destroy))
                .subscribe(() => {
                    this.progress += 5;
                    if (this.progress >= 95) {
                        this.destroy.next();
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }
}
