import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
    Sentry.init({
        dsn: environment.sentry,
        maxValueLength: 8000,
        beforeSend: (event, hint) => {
            if (hint?.originalException) {
                const error = hint.originalException as any;
                if (typeof error?.status === 'number' && error.status < 500) {
                    return null;
                }
            }
            return event;
        },
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
