import { Component, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface UnsavedChanges {
    hasChanges: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class UnsavedChangesGuard implements CanDeactivate<UnsavedChanges> {
    constructor(private readonly dialog: MatDialog) {}

    canDeactivate(
        component: UnsavedChanges,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | boolean {
        if (!component.hasChanges) {
            return true;
        }

        const dialog = this.dialog.open(DialogUnsavedChanges, {
            maxWidth: '340px',
        });

        return dialog.afterClosed();
    }
}

@Component({
    selector: 'dialog-unsaved-alert',
    template: `<div style="padding:10px">
        <div mat-dialog-content>Wenn Sie die Seite verlassen gehen Ihre ungespeicherten Änderungen verloren.</div>
        <div style="margin-top: 16px" fxLayout="row" fxLayoutAlign="space-between center" mat-dialog-actions>
            <button mat-stroked-button (click)="dialogRef.close(true)">Verlassen</button>
            <button mat-flat-button color="primary" (click)="dialogRef.close(false)" cdkFocusInitial>Bleiben</button>
        </div>
    </div> `,
})
export class DialogUnsavedChanges {
    constructor(public dialogRef: MatDialogRef<DialogUnsavedChanges>) {}
}
