import { AoAuthService } from '@ah-oh/company-auth';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompaniesService } from './companies.service';
import { UsersService } from './users.service';
@Injectable({
    providedIn: 'root',
})
export class InitService {
    constructor(
        private readonly authService: AoAuthService,
        private readonly companiesService: CompaniesService,
        private readonly usersService: UsersService,
    ) {
        authService.$signIn.subscribe((status) => {
            if (status) {
                if (!environment.production && authService.signedInRes?.token) {
                    localStorage.setItem('dev-token', authService.signedInRes.token);
                }
            } else {
                localStorage.removeItem('dev-token');
            }
        });
    }

    async init(): Promise<void> {
        return new Promise((resolve) => {
            this.authService.refreshToken().subscribe(
                (res) => {
                    if (!environment.production && res.token) {
                        localStorage.setItem('dev-token', res.token);
                    }
                    forkJoin([this.companiesService.findMine(), this.usersService.findMine()]).subscribe(
                        () => {
                            resolve();
                        },
                        () => {
                            resolve();
                        },
                    );
                },
                () => {
                    resolve();
                },
            );
        });
    }
}
