import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },

    {
        path: 'einstellungen',
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsModule),
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
        path: 'sammeldruck',
        loadChildren: () =>
            import('./pages/collective-print/collective-print.module').then((m) => m.CollectivePrintModule),
    },
    {
        path: 'spenden-auto-zuordnung',
        loadChildren: () =>
            import('./pages/donations-auto-assign/donations-auto-assign.module').then(
                (m) => m.DonationsAutoAssignModule,
            ),
    },
    {
        path: 'auswertungen',
        loadChildren: () => import('./pages/evaluation/evaluation.module').then((m) => m.EvaluationModule),
    },
    { path: 'aktionen', loadChildren: () => import('./pages/projects/projects.module').then((m) => m.ProjectsModule) },
    { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule) },
    { path: 'nutzer', loadChildren: () => import('./pages/users/users.module').then((m) => m.UsersModule) },
    { path: 'spender', loadChildren: () => import('./pages/donators/donators.module').then((m) => m.DonatorsModule) },
    {
        path: 'manual-assign',
        loadChildren: () =>
            import('./pages/donations-manual-assign/donations-manual-assign.module').then(
                (m) => m.DonationsManualAssignModule,
            ),
    },
    { path: 'setup', loadChildren: () => import('./pages/setup/setup.module').then((m) => m.SetupModule) },
    {
        path: 'import',
        loadChildren: () => import('./pages/import/import.module').then((m) => m.ImportModule),
    },
    {
        path: 'logs',
        loadChildren: () => import('./pages/logs/logs.module').then((m) => m.LogsModule),
    },
    {
        path: 'schnittstellen',
        loadChildren: () => import('./pages/channels/channels.module').then((m) => m.ChannelsModule),
    },
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
