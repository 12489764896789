import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

//** interface can be extended for more info visit https://froala.com/wysiwyg-editor/docs/options/ */
interface FroalaOptions {
    key: string;
    attribution: boolean;
    toolbarButtons: { [key: string]: { buttons: string[]; buttonsVisible: number; align?: string } };
    pluginsEnabled: string[];
    paragraphFormat: { [key: string]: string };
    emoticonsUseImage: boolean;
    paragraphFormatSelection: boolean;
    paragraphDefaultSelection: string;
    fontFamily: { [key: string]: string };
    toolbarSticky: boolean;
}

@Component({
    selector: 'app-text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent {
    @Input() editorControl!: FormControl;
    @Input() placeholder = '';
    @Input() headline = '';

    options: FroalaOptions = {
        key: environment.froalaKey,
        attribution: false,
        toolbarSticky: false,
        toolbarButtons: {
            basics: {
                buttons: ['undo', 'redo', 'bold', 'italic', 'underline', 'clearFormatting', 'fontFamily'],
                buttonsVisible: 7,
            },
            moreText: {
                buttons: ['fontSize', 'backgroundColor'],
                buttonsVisible: 0,
            },
            moreMisc: {
                buttons: ['fullscreen', 'print', 'getPDF', 'selectAll', 'html', 'help'],
                buttonsVisible: 0,
                align: 'right',
            },
            formatting: {
                buttons: [
                    'alignLeft',
                    'alignCenter',
                    'alignRight',
                    'alignJustify',
                    'paragraphFormat',
                    'formatUL',
                    'formatOL',
                    'insertLink',
                    'indent',
                    'outdent',
                ],
                buttonsVisible: 10,
            },
            moreRich: {
                buttons: ['insertTable', 'emoticons', 'specialCharacters', 'insertHR'],
                buttonsVisible: 0,
            },
        },
        pluginsEnabled: [
            'lists',
            'link',
            'align',
            'table',
            'paragraphFormat',
            'codeView',
            'fontSize',
            'fontFamily',
            'help',
            'fullscreen',
            'print',
            'emoticons',
            'specialCharacters',
            'colors',
        ],
        paragraphFormat: {
            N: 'Normal',
            H1: 'Heading 1',
            H2: 'Heading 2',
            H3: 'Heading 3',
            H4: 'Heading 4',
            H5: 'Heading 5',
            H6: 'Heading 6',
        },
        emoticonsUseImage: false,
        paragraphFormatSelection: true,
        paragraphDefaultSelection: 'Normal',
        fontFamily: {
            'Fira Sans,sans-serif': 'Fira Sans',
            'Open Sans,sans-serif': 'Open Sans',
            'Lato,sans-serif': 'Lato',
            'Roboto,sans-serif': 'Roboto',
            'Archivo,sans-serif': 'Archivo',
        },
    };
}
