<h1 mat-dialog-title>Ein Update ist verfügbar</h1>
<div mat-dialog-content>
    <div [innerHtml]="data.description"></div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Schließen</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
        Jetzt aktualisieren
    </button>
</mat-dialog-actions>
