import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberToPrice',
})
export class NumberToPricePipe implements PipeTransform {
    transform(num: number | null | undefined): string {
        const numStr = String(num);
        if (Number.isNaN(Number.parseFloat(numStr))) {
            return '';
        }

        const numArr = numStr.split('.');
        numArr[0] = numArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        const price = numArr.length === 1 ? numArr[0] + ',00' : numArr[0] + this.addZero(numArr[1]);

        return price + ' €';
    }

    private addZero(num: string): string {
        if (num.length > 2) {
            return ',' + num.substring(0, 2);
        }
        return num.length === 1 ? ',' + num + '0' : ',' + num;
    }
}
