import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AoAuthService } from '@ah-oh/company-auth';
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'secure',
})
export class SecureFilePipe implements PipeTransform {
    constructor(private readonly http: HttpClient, private readonly authService: AoAuthService) {}

    transform(url: string) {
        return new Observable<string>((observer) => {
            // This is a tiny blank image

            observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

            // The next and error callbacks from the observer
            const { next, error } = observer;

            let headers = new HttpHeaders();

            if (!headers.get('Authorization')) {
                if (this.authService.signedInRes?.token) {
                    headers = headers.append('Authorization', this.authService.signedInRes.token);
                } else if (!environment.production) {
                    const token = localStorage.getItem('dev-token');
                    if (token) {
                        headers = headers.append('Authorization', token);
                    }
                }
            }

            this.http.get(url, { responseType: 'blob', headers }).subscribe(
                (response) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(response);
                    reader.onloadend = function () {
                        observer.next(String(reader.result));
                    };
                },
                (err) => {
                    console.log(err);
                },
            );

            return {
                unsubscribe() {
                    // unsubscribe
                },
            };
        });
    }
}
