import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AoFilterHeaderSettings } from '../ao-filter-list-header.component';

@Component({
    selector: 'app-dialog-ao-mobile-filter',
    templateUrl: './dialog-ao-mobile-filter.component.html',
    styleUrls: ['./dialog-ao-mobile-filter.component.scss'],
})
export class DialogAoMobileFilterComponent {
    constructor(
        public readonly dialogRef: MatDialogRef<DialogAoMobileFilterComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly settings: AoFilterHeaderSettings,
    ) {}
}
