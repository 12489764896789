import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TypeForm } from 'src/app/utils/type-form';
import { environment } from 'src/environments/environment';
import { EmailSettingsDto } from './email-settings.dto';
import { EmailSettings } from './email-settings.model';

@Injectable({
    providedIn: 'root',
})
export class EmailService {
    private static readonly BASE_URL = environment.api + 'email';

    constructor(private readonly http: HttpClient) {}

    setValidation(form: TypeForm): void {
        const customSMTP = form.getFormControl('customSMTP').value;

        form.getForm('smtpSettings').setRequired({
            required: customSMTP,
            emit: true,
            names: ['server', 'email', 'password', 'username', 'port', 'encryption'],
        });
    }

    find(companyId: string): Observable<EmailSettings> {
        return this.http.get<EmailSettings>(`${EmailService.BASE_URL}/${companyId}`);
    }

    createUpdate({companyId,dto}:{companyId: string, dto: EmailSettingsDto}): Observable<EmailSettings> {
        return this.http.put<EmailSettings>(
            `${EmailService.BASE_URL}/${companyId}`,

            dto,
        );
    }

    testConnection(companyId: string): Observable<Response> {
        return this.http.get<Response>(EmailService.BASE_URL + '/' + companyId + '/test-connection');
    }
}
