<div class="second-header">
    <div *ngIf="!activeMobileSearch" fxLayout="row" id="row-container" fxLayoutAlign="start center">
        <div
            fxLayout="column"
            [fxFlex]="33.333333"
            fxFlex.lt-lg="calc(100% - 90px)"
            class="headline headline-big"
            *ngIf="headline"
        >
            <div fxLayout="row" fxLayoutGap="32px">
                <h1 *ngIf="!showButton">
                    <button (click)="back.emit()" *ngIf="backLink" [routerLink]="backLink" mat-icon-button>
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                    {{ headline }}
                </h1>

                <div *ngIf="showButton">
                    <button
                        (click)="back.emit()"
                        *ngIf="backLink"
                        [routerLink]="backLink"
                        color="primary"
                        mat-stroked-button
                        style="margin-bottom: 16px"
                    >
                        <mat-icon>chevron_left</mat-icon> {{ headline }}
                    </button>
                </div>
            </div>

            <mat-checkbox
                *ngIf="hasSelect"
                style="margin-left: 10px"
                class="checkbox-block"
                color="primary"
                [(ngModel)]="archived"
                (ngModelChange)="selectChange.emit(archived)"
                >{{ selectText }}</mat-checkbox
            >
        </div>
        <div fxLayout="column" fxFlex="33.333333" fxHide.lt-lg id="search" *ngIf="searchBar">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Suche</mat-label>
                <input
                    type="text"
                    matInput
                    [(ngModel)]="searchString"
                    maxlength="256"
                    (ngModelChange)="search.emit(searchString)"
                />
            </mat-form-field>
        </div>
        <div fxFlex="66.666666" fxFlex.lt-lg="auto">
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end center" class="add-icon-container">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
    <div *ngIf="activeMobileSearch" id="mobile-search-container" fxLayout="row">
        <div fxFlex="calc(100% - 20px)">
            <input
                class="search"
                type="text"
                [(ngModel)]="searchString"
                maxlength="256"
                placeholder="Suche"
                (ngModelChange)="search.emit(searchString)"
            />
        </div>
        <div style="text-align: right" fxFlex="auto">
            <button class="close-btn" (click)="activeMobileSearch = false" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
        <nav
            *ngIf="navLinks && navLinks.length > 0"
            mat-tab-nav-bar
            class="navigation"
            [fxFlex]="100"
            [tabPanel]="tabPanel"
            mat-stretch-tabs="false"
        >
            <a
                mat-tab-link
                *ngFor="let link of navLinks"
                [routerLink]="link.path"
                [queryParams]="link.params"
                routerLinkActive
                #rla="routerLinkActive"
                [class.active]="rla.isActive"
            >
                {{ link.label }}
            </a>
        </nav>
        <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    </div>
</div>
