import { PdfJobService } from '@ah-oh/ng-pdf-api';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { Socket } from 'ngx-socket-io';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-dialog-download-pdf',
    templateUrl: './dialog-download-pdf.component.html',
    styleUrls: ['./dialog-download-pdf.component.scss'],
})
export class DialogDownloadPdfComponent implements OnInit, OnDestroy {
    pdfJobId?: string;

    constructor(
        public readonly dialogRef: MatDialogRef<DialogDownloadPdfComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: { pdfApiClient: string },
        private readonly socket: Socket,

        private readonly pdfJobsService: PdfJobService,
    ) {}

    private readonly destroy = new Subject<void>();

    ngOnInit(): void {
        this.socket
            .fromEvent<string>('new-pdf')
            .pipe(takeUntil(this.destroy))
            .subscribe((res: string) => {
                this.pdfJobId = res;
            });
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    download(): void {
        if (this.pdfJobId) {
            this.pdfJobsService.findFile(this.data.pdfApiClient, this.pdfJobId, true).subscribe((res: any) => {
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(res.body);
                downloadLink.setAttribute('download', `fundracer-${dayjs().format('DD.MM.YYYY-HH:mm:ss')}.pdf`);
                document.body.appendChild(downloadLink);
                downloadLink.click();
            });
        }
    }
}
