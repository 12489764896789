<div class="ao-nav-bar" id="nav-bar" *ngIf="isSignedIn">
    <a id="spenden-icon" routerLink="/">
        <img src="assets/logo.jpg" width="50" style="pointer-events: none" />
    </a>
    <div class="nav-container" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="nav-inner" fxFlex="auto">
            <div style="height: 48px">
                <div class="mobile-visibility" style="height: 48px">
                    <a
                        matBadge="1"
                        [matBadgeHidden]="navLink.label !== 'Spendeneingang' || tasksFinished"
                        class="nav-link hide-badge"
                        routerLinkActive="active"
                        [routerLink]="navLink.path"
                        *ngFor="let navLink of navLinks"
                    >
                        {{ navLink.label }}</a
                    >
                </div>
            </div>
        </div>

        <div fxFlex="35px" id="loading-spinner" *ngIf="activeRequest">
            <mat-spinner color="primary" [diameter]="25"></mat-spinner>
        </div>

        <div id="right-nav-part">
            <div class="username">
                <a
                    *ngIf="user"
                    [routerLink]="'/nutzer/profil/' + user.authId"
                    routerLinkActive="active"
                    class="nav-link"
                >
                    {{ user.username }}
                </a>
            </div>

            <div class="header-icons" fxLayout="row" fxLayoutAlign="space-around center">
                <div>
                    <a mat-icon-button style="z-index: 2" [matMenuTriggerFor]="pdfJobMenu">
                        <mat-icon>save_alt</mat-icon>
                    </a>
                    <app-download-badge [pdfJobs]="pdfJobs" [pdfMenuSeen]="pdfMenuSeen"></app-download-badge>
                </div>
                <div>
                    <a mat-icon-button *ngIf="permission.SETTINGS" routerLinkActive="active" routerLink="/einstellungen"
                        ><mat-icon>settings</mat-icon></a
                    >
                </div>
                <div>
                    <a mat-icon-button (click)="signOut()"><mat-icon>power_settings_new</mat-icon></a>
                </div>

                <div class="desktop-visibility" *ngIf="!seeSideNav">
                    <a mat-icon-button (click)="seeSideNav = true"><mat-icon>menu</mat-icon></a>
                </div>
                <div class="desktop-visibility" *ngIf="seeSideNav">
                    <a mat-icon-button (click)="seeSideNav = false"><mat-icon>close</mat-icon></a>
                </div>
            </div>
        </div>
    </div>
</div>

<mat-menu class="no-padding pdf-job-menu" #pdfJobMenu="matMenu" (closed)="pdfMenuSeen = true">
    <div style="padding: 16px" (click)="$event.stopPropagation()" *ngIf="pdfJobs.length === 0">
        Es befindet sich kein PDF in der Warteschlange
    </div>
    <app-pdf-jobs [pdfJobs]="pdfJobs" (download)="downloadPdf($event)" (delete)="deletePdf($event)"></app-pdf-jobs>
</mat-menu>

<div class="side-nav desktop-visibility" [class.open]="seeSideNav">
    <div class="content">
        <div *ngFor="let navLink of navLinks" (click)="seeSideNav = false" [class.hide]="!seeSideNav">
            <a
                class="nav-link"
                [routerLink]="navLink.path"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: navLink.exact ?? false }"
                >{{ navLink.label }}</a
            >
        </div>
    </div>
</div>
