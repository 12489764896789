<div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="16px"
    class="download-btn"
    [class.disabled]="disabled"
    (click)="fileUpload.click()"
>
    <div class="text">zugehörige Dokumente hochladen (JPEG, PNG, PDF. Max. Größe: 10MB)</div>
    <div class="icon">
        <mat-icon>file_upload</mat-icon>
    </div>
</div>
<input
    hidden
    type="file"
    id="fileUpload"
    #fileUpload
    (change)="onSelectFile($event)"
    accept="image/jpeg, image/jpg, image/gif, image/png, application/pdf"
    [disabled]="disabled"
/>
<mat-chip-listbox>
    <div class="chip-container" *ngFor="let file of files; index as i">
        <mat-chip-option
            [selectable]="false"
            [removable]="!disabled"
            (removed)="removeChip(i)"
            (click)="onOpenFile(file)"
        >
            {{ file.name }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
    </div>
</mat-chip-listbox>
