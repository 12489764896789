import { Pipe, PipeTransform } from '@angular/core';
import { DonatorDto } from '../dtos/donators/donator.dto';
import { Donator } from '../models/donator.interface';

@Pipe({
    name: 'donatorAuthNr',
})
export class DonatorAuthNrPipe implements PipeTransform {
    transform(value: Donator | DonatorDto): string {
        if ((<Donator>value).donatorAuthNr) {
            return (<Donator>value).donatorAuthNr;
        }

        return '';
    }
}
