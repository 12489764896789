import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { AoFilterHeaderField } from '../ao-filter-list-header.component';
import { Subject, debounce, debounceTime } from 'rxjs';

@Component({
    selector: 'app-filter-date-range',
    templateUrl: './filter-date-range.component.html',
    styleUrls: ['./filter-date-range.component.scss'],
})
export class FilterDateRangeComponent implements OnInit, OnDestroy {
    @Input() field!: AoFilterHeaderField;
    @Input() form!: UntypedFormGroup;

    private _filter!: any;
    get filter(): any {
        return this._filter;
    }
    @Input() set filter(value: any) {
        this._filter = value;
        this.setValues();
    }

    @Output() sortChange = new EventEmitter<void>();
    @Output() resetRange = new EventEmitter<void>();

    range: FormGroup = new FormGroup({
        startDate: new FormControl<Date | null>(null),
        endDate: new FormControl<Date | null>(null),
    });

    public dateChangedSub = new Subject<void>();
    public active = false;
    private readonly _destroy = new Subject<void>();

    clear(): void {
        this.range.reset();
        this.form.get(this.field.name)?.setValue(this.range.value);
    }

    ngOnInit(): void {
        const startDateControl = this.range.controls.startDate;
        const endDateControl = this.range.controls.endDate;

        this.dateChangedSub.pipe(debounceTime(500)).subscribe(() => {
            const startDate = startDateControl.value;
            const endDate = endDateControl.value;
            if (this.range.valid && startDate && endDate) {
                this.form.get(this.field.name)?.setValue({ startDate, endDate });
            }
        });

        this.range.valueChanges.subscribe(() => {
            this.active = startDateControl.value && endDateControl.value;
        });

        this.setValues();
    }

    setValues(): void {
        const val = this.filter[this.field.name];
        if (val) {
            this.range.controls.startDate.setValue(new Date(val.startDate));
            this.range.controls.endDate.setValue(new Date(val.endDate));
        }
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }
}
