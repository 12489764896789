import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { PrintDocument } from '../models/print-documents/print-document.interface';
import { UpdatePrintDocumentDto } from '../models/print-documents/update-print-document.dto';
import { CreatePrintDocumentDto } from '../models/print-documents/create-print-document.dto';
import { CreateCollectionLetterPdfDto } from '../models/print-documents/create-collection-letter-pdf.dto';
import { PrintDocumentOverview } from '../models/print-documents/print-document-overview.interface';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class PrintDocumentsService {
    private readonly newPdfJobSubject = new Subject<boolean>();
    newPdfJobSub = this.newPdfJobSubject.asObservable();

    private static readonly BASE_URL = environment.api + 'print-documents';

    constructor(private readonly http: HttpClient, private readonly snackBar: MatSnackBar) {
        this.newPdfJobSub.subscribe((print) => {
            this.snackBar.open(`Der ${print ? 'Druck' : 'PDF'}-Auftrag wurde erfolgreich eingereiht`, '', {
                duration: 4000,
            });
        });
    }

    findAll(): Observable<PrintDocumentOverview[]> {
        return this.http.get<PrintDocumentOverview[]>(PrintDocumentsService.BASE_URL);
    }

    findOne(id: string): Observable<PrintDocument> {
        return this.http.get<PrintDocument>(PrintDocumentsService.BASE_URL + '/' + id);
    }

    update({ id, dto }: { id: string; dto: UpdatePrintDocumentDto }): Observable<PrintDocument> {
        return this.http.post<PrintDocument>(PrintDocumentsService.BASE_URL + '/' + id, dto);
    }

    create(dto: CreatePrintDocumentDto): Observable<PrintDocument> {
        return this.http.put<PrintDocument>(PrintDocumentsService.BASE_URL, dto);
    }

    delete(id: string): Observable<PrintDocument> {
        return this.http.delete<PrintDocument>(PrintDocumentsService.BASE_URL + '/' + id);
    }

    findAndPrint({ id, dto }: { id: string; dto: CreateCollectionLetterPdfDto }): Observable<any> {
        return this.http.post(`${PrintDocumentsService.BASE_URL}/${id}/collection-print`, dto).pipe(
            tap(() => {
                this.newPdfJobSubject.next(dto.print);
            }),
        );
    }
}
