import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-color-input',
    templateUrl: './color-input.component.html',
    styleUrls: ['./color-input.component.scss'],
})
export class ColorInputComponent implements OnDestroy {
    @Input() name?: string;
    @Input() height!: string;

    private _color: UntypedFormControl | undefined;
    get color(): UntypedFormControl | undefined {
        return this._color;
    }
    @Input() set color(value: UntypedFormControl | undefined) {
        this._color = value;

        if (this.color) {
            this.setData();
        }
    }

    private _activeItem?: any;
    get activeItem(): any {
        return this._activeItem;
    }
    @Input() set activeItem(value: any) {
        this._activeItem = value;

        if (this.color?.value) {
            this.colorInput = this.color.value;
        }
    }

    @Output() deleteEvent: EventEmitter<void> = new EventEmitter<void>();

    colorInput = '#000000';

    private readonly destroy = new Subject<void>();

    private setData(): void {
        if (this.color) {
            this.color.valueChanges.pipe(takeUntil(this.destroy)).subscribe((val) => {
                if (val !== this.colorInput) {
                    this.colorInput = val;
                }
            });

            if (this.color.value) {
                this.colorInput = this.color.value;
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    changeColor() {
        if (this.color) {
            this.color.setValue(this.colorInput);
        }
    }

    deleteField(): void {
        this.deleteEvent.emit();
    }
}
