export enum AlertType {
    ALTRUJA_DEFAULT = 'alert',
    FIN_API_DEFAULT = 'fin-api-default',
    ALTRUJA_UNAUTHORIZED = 'altruja-unauthorized',
}

export interface Alert {
    _id: string;
    message: string;
    type: AlertType;
    hide: boolean;
    company: string;
    createdAt: Date;
    updatedAt: Date;
}
