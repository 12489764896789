import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
    name: 'dateToTime',
})
export class DateToTimePipe implements PipeTransform {
    transform(value: any): string {
        const date = dayjs(value);

        if (date.isValid()) {
            return date.format('HH:mm') + ' Uhr';
        } else {
            return '-';
        }
    }
}
