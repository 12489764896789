import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-custom-btn',
    templateUrl: './custom-btn.component.html',
    styleUrls: ['./custom-btn.component.scss'],
})
export class CustomBtnComponent {
    @Input() name!: string;
    @Input() active = false;
    @Input() disabled = false;
    @Output() activeChange = new EventEmitter<boolean>();

    toggle(): void {
        if (!this.disabled) {
            this.activeChange.emit(this.active);
        }
    }
}
