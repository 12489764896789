import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { AoFilterHeaderField, AoFilterHeaderSelect } from '../ao-filter-list-header.component';

@Component({
    selector: 'app-filter-multi-select',
    templateUrl: './filter-multi-select.component.html',
    styleUrls: ['./filter-multi-select.component.scss'],
})
export class FilterMultiSelectComponent implements OnInit, OnDestroy {
    @Input() field!: AoFilterHeaderField;
    @Input() control!: UntypedFormControl;

    private _selectValues?: AoFilterHeaderSelect[] | undefined;
    get selectValues(): AoFilterHeaderSelect[] | undefined {
        return this._selectValues;
    }
    @Input() set selectValues(value: AoFilterHeaderSelect[] | undefined) {
        this._selectValues = value;

        if (this.selectValues?.length) {
            this.setForm();
        }
    }

    @Output() sortChange = new EventEmitter<void>();

    form = new UntypedFormGroup({});
    formSet = false;
    public hasValue = true;
    public valueText = '';

    private readonly destroy = new Subject<void>();

    ngOnInit(): void {
        this.form.valueChanges.pipe(takeUntil(this.destroy), debounceTime(500)).subscribe((v: any) => {
            const newValue = this.selectValues?.filter((val) => v[val.value]).map((o) => o.value);
            this.hasValue = !!newValue?.length;
            this.valueText =
                this.selectValues && this.hasValue
                    ? this.selectValues
                          .filter((val) => v[val.value])
                          .map((o) => o.name)
                          .join()
                    : '';
            this.control.setValue(newValue);
        });
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    setForm(): void {
        if (this.selectValues?.length) {
            this.hasValue = !!this.control.value?.length;
            this.valueText = this.hasValue
                ? this.selectValues
                      .filter((val) => this.control.value.includes(val.value))
                      .map((o) => o.name)
                      .join()
                : '';

            for (const option of this.selectValues) {
                this.form.addControl(
                    option.value,
                    new UntypedFormControl(this.control.value.includes(option.value) ? option.value : ''),
                    { emitEvent: false },
                );
            }

            this.formSet = true;
        }
    }

    clear(): void {
        this.form.reset();
    }
}
