import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface LoaderState {
    show: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private activeRequests = 0;
    private readonly loaderSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();

    show(): void {
        setTimeout(() => {
            this.activeRequests++;
            this.loaderSubject.next({ show: true });
        }, 0);
    }

    hide(): void {
        setTimeout(() => {
            this.activeRequests--;
            if (this.activeRequests < 1) {
                this.loaderSubject.next({ show: false });
            }
        }, 0);
    }
}
