import { Injectable } from '@angular/core';
import { Company } from '../models/company.interface';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateCompanyDto } from '../dtos/create-company.dto';
import { UpdateCompanyDto } from '../dtos/update-company.dto';

@Injectable({
    providedIn: 'root',
})
export class CompaniesService {
    private static readonly BASE_URL = environment.api + 'companies';

    constructor(private readonly http: HttpClient) {}

    findAll(): Observable<Company[]> {
        return this.http.get<Company[]>(CompaniesService.BASE_URL);
    }

    findOne(id: string): Observable<Company> {
        return this.http.get<Company>(CompaniesService.BASE_URL + '/' + id);
    }

    findMine(): Observable<Company> {
        return this.http.get<Company>(CompaniesService.BASE_URL + '/mine');
    }

    search(name: string): Observable<Company[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('name', name);
        return this.http.get<Company[]>(`${CompaniesService.BASE_URL}/search`, { params });
    }

    update({ id, dto }: { id: string; dto: UpdateCompanyDto }): Observable<Company> {
        return this.http.post<Company>(CompaniesService.BASE_URL + '/' + id, dto);
    }

    create(dto: CreateCompanyDto): Observable<Company> {
        return this.http.put<Company>(CompaniesService.BASE_URL, dto);
    }

    delete(id: string): Observable<Company> {
        return this.http.delete<Company>(CompaniesService.BASE_URL + '/' + id);
    }

    getPrivatePictureUrl(name: string): string {
        return `${CompaniesService.BASE_URL}/private-picture/${name}`;
    }
}
