<button matBadge="1" [matBadgeHidden]="!isUnsavedFilter" class="hide-badge" [matMenuTriggerFor]="menu" mat-icon-button>
    <mat-icon>tune</mat-icon>
</button>

<mat-menu #menu="matMenu">
    <div>
        <div (click)="filterSelected.emit()" class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-icon>refresh</mat-icon>
            <div>Alle Filter zurücksetzten</div>
        </div>
        <div (click)="save()" class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-icon>save</mat-icon>
            <div>Aktuelle Filtereinstellungen speichern</div>
        </div>
        <div
            (click)="expanded = !expanded; $event.stopPropagation()"
            *ngIf="fields.length > 9"
            class="row"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
        >
            <div class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-icon style="padding-left: 5px">filter_alt</mat-icon>
                <div>Filter ein- und ausblenden</div>
            </div>
            <mat-icon *ngIf="!expanded">expand_more</mat-icon>
            <mat-icon *ngIf="expanded">expand_less</mat-icon>
        </div>
        <div *ngIf="expanded" class="fields" (click)="$event.stopPropagation()">
            <div
                *ngFor="let field of fields"
                class="field"
                [class.inactive]="field.locked || hidden.includes(field.name)"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
            >
                <mat-icon *ngIf="field.locked">lock</mat-icon>
                <mat-icon
                    class="pointer"
                    (click)="toggleHide(field.name, true)"
                    *ngIf="!field.locked && !hidden.includes(field.name)"
                    >visibility</mat-icon
                >
                <mat-icon
                    class="pointer"
                    [matTooltip]="'Es können maximal 9 Filter angezeigt werden'"
                    [matTooltipDisabled]="fields.length - hidden.length < 9"
                    (click)="toggleHide(field.name)"
                    *ngIf="!field.locked && hidden.includes(field.name)"
                    >visibility_off</mat-icon
                >
                <div>{{ field.placeholder }}</div>
            </div>
        </div>
    </div>

    <div *ngIf="filters.length">
        <div class="border" style="padding-bottom: 2px">Meine Filter</div>

        <div
            *ngFor="let filter of filters; index as i"
            (click)="selectFilter(filter)"
            class="filter"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
        >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-icon>tune</mat-icon>
                <div>{{ filter }}</div>
            </div>
            <mat-icon (click)="deleteFilter({filter, index:i})">delete</mat-icon>
        </div>
    </div>
</mat-menu>
