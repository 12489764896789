import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum PageVisibility {
    VISIBLE = 'visible',
    INVISIBLE = 'invisible',
}

@Injectable({
    providedIn: 'root',
})
export class PageLifecycleService {
    private readonly visibilitySubject = new Subject<PageVisibility>();
    visibilityChange = this.visibilitySubject.asObservable();

    private readonly resumeSubject = new Subject<void>();
    resume = this.resumeSubject.asObservable();

    constructor() {
        document.addEventListener('visibilitychange', () => {
            this.visibilitySubject.next(document.hidden ? PageVisibility.INVISIBLE : PageVisibility.VISIBLE);
        });
        document.addEventListener('resume', () => {
            this.resumeSubject.next();
        });
    }
}
