<div class="box">
    <div *ngIf="connected">
        <button mat-flat-button color="primary" (click)="connected = false">
            {{ alreadyConnected ? 'Jetzt neu verbinden' : 'Jetzt verbinden' }}
        </button>
    </div>
    <form *ngIf="!connected" [formGroup]="form" (submit)="onSubmit()" autocomplete="off" style="margin-top: 16px">
        <mat-form-field class="full-width slim-field" appearance="fill">
            <mat-label>Client</mat-label>
            <input matInput formControlName="client" autocomplete="off" />
            <div class="ao-mat-error" [class.open]="form.get('client')?.touched && form.get('client')?.invalid">
                <div *ngIf="form.get('client')?.hasError('required')">Pflichtfeld</div>

                <div *ngIf="!form.get('client')?.hasError('required') && form.get('client')?.hasError('incorrect')">
                    {{ errorText }}
                </div>
            </div>
        </mat-form-field>

        <mat-form-field class="full-width slim-field" appearance="fill">
            <mat-label>Secret</mat-label>
            <input matInput formControlName="secret" autocomplete="off" />

            <div class="ao-mat-error" [class.open]="form.get('secret')?.touched && form.get('secret')?.invalid">
                <div *ngIf="form.get('secret')?.hasError('required')">Pflichtfeld</div>
                <div *ngIf="!form.get('secret')?.hasError('required') && form.get('secret')?.hasError('incorrect')">
                    {{ errorText }}
                </div>
            </div>
        </mat-form-field>
        <div *ngIf="productionCheck" style="margin-bottom: 1rem">
            <mat-slide-toggle formControlName="production">Live-Daten</mat-slide-toggle>
        </div>

        <div *ngIf="methodCheck">
            <div class="bold">Einzelspende Bezahlmethoden</div>
            <mat-button-toggle-group
                color="primary"
                formControlName="instantMethodes"
                aria-label="Bezahlmethoden Einzelspende"
                multiple
            >
                <mat-button-toggle value="card">Kreditkarte</mat-button-toggle>
                <mat-button-toggle value="giropay">Giropay</mat-button-toggle>
                <mat-button-toggle value="klarna">Klarna</mat-button-toggle>
                <mat-button-toggle value="sepa_debit">Sepa</mat-button-toggle>
                <mat-button-toggle value="sofort">Sofort</mat-button-toggle>
            </mat-button-toggle-group>

            <div class="bold">Dauerspende Bezahlmethoden</div>
            <mat-button-toggle-group
                color="primary"
                formControlName="subscriptionMethodes"
                aria-label="Bezahlmethoden Dauerspende"
                multiple
            >
                <mat-button-toggle value="card">Kreditkarte</mat-button-toggle>
                <mat-button-toggle value="sepa_debit">Sepa</mat-button-toggle>
            </mat-button-toggle-group>

            <p>
                Achtung! Die Bezahlmethoden müssen von Ihnen ggf. erst bei dem Bezahldienstleister konfiguriert werden.
            </p>
        </div>

        <div class="ao-warning" *ngIf="warning">
            <p>{{ warning }}</p>
        </div>

        <div class="ao-error" *ngIf="globalError">
            <p>{{ errorText }}</p>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-flat-button color="primary" [loading]="activeRequest">Jetzt verbinden</button>
        </div>
    </form>
</div>
