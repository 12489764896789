<div class="field-container">
    <div [matMenuTriggerFor]="menu" class="field-name" [class.active]="active">
        <div class="placeholder" [class.active]="active">{{ field.placeholder }}</div>
        <div class="value-text" *ngIf="active">
            <div>{{ range.get('startDate')?.value | dateToFullDateString }} -</div>
            <div>{{ range.get('endDate')?.value | dateToFullDateString }}</div>
        </div>
    </div>

    <mat-menu class="filter-menu" #menu="matMenu">
        <div class="field-content" (click)="$event.stopPropagation()">
            <div class="heading">{{ field.placeholder }}</div>

            <div class="icons" fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                    <span *ngIf="field.sortKey" [class.active]="field.sortState">
                        <mat-icon
                            (click)="sortChange.emit()"
                            class="sort-icon"
                            [class.active]="field.sortState"
                            [class.rotate]="field.sortState === 'desc'"
                            >north</mat-icon
                        >
                    </span>
                </div>
                <mat-icon (click)="clear()">highlight_off</mat-icon>
            </div>

            <div class="date-range">
                <mat-form-field appearance="fill" class="full-width slim-field">
                    <mat-label>{{ field.placeholder }}</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input
                            formControlName="startDate"
                            (dateInput)="dateChangedSub.next()"
                            matStartDate
                            placeholder="Start date"
                        />
                        <input
                            formControlName="endDate"
                            (dateInput)="dateChangedSub.next()"
                            matEndDate
                            placeholder="End date"
                        />
                    </mat-date-range-input>
                    <mat-datepicker-toggle (click)="picker.open()" matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
        </div>
    </mat-menu>
</div>
