<div class="overlay">
    <div class="btn-container">
        <button
            mat-icon-button
            [style.color]="inactiveColor"
            (click)="stop()"
            [ngStyle]="style"
            [ngClass]="percent === 0 ? 'inactive' : 'active'"
        >
            <mat-icon>{{ icon }}</mat-icon>
        </button>
    </div>
</div>
