<div class="field-container">
    <div
        [matMenuTriggerFor]="menu"
        [class.active]="control.value || field.sortState || control.value === false"
        class="field-name"
    >
        <div>
            {{ field.placeholder }}
        </div>
    </div>

    <mat-menu class="filter-menu" #menu="matMenu">
        <div class="field-content" (click)="$event.stopPropagation()">
            <div class="heading">{{ field.placeholder }}</div>

            <div class="icons" fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                    <span *ngIf="field.sortKey" [class.active]="field.sortState">
                        <mat-icon
                            (click)="sortChange.emit()"
                            class="sort-icon"
                            [class.active]="field.sortState"
                            [class.rotate]="field.sortState === 'desc'"
                            >north</mat-icon
                        >
                    </span>
                </div>
                <mat-icon (click)="clear()">highlight_off</mat-icon>
            </div>

            <mat-radio-group class="radio-blocks" [formControl]="control">
                <mat-radio-button *ngFor="let item of field.selectValues" [value]="item.value">
                    {{ item.name }}</mat-radio-button
                >
            </mat-radio-group>
        </div>
    </mat-menu>
</div>
