import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';
import { DialogAddFilterComponent } from '../dialog-add-filter/dialog-add-filter.component';
import { AoFilterHeaderField } from '../ao-filter-list-header.component';

export enum FilterPageType {
    DONATIONS = 'donations',
    DONATORS = 'donators',
    EVALUATION = 'evaluation',
}
@Component({
    selector: 'app-filter-settings',
    templateUrl: './filter-settings.component.html',
    styleUrls: ['./filter-settings.component.scss'],
})
export class FilterSettingsComponent implements OnInit {
    private _filter: any;
    get filter(): any {
        return this._filter;
    }
    @Input() set filter(value: any) {
        this._filter = value;

        if (this.filter && !this.isEmpty()) {
            this.setUnsaved();
        } else {
            this.isUnsavedFilter = false;
        }
    }

    @Input() pageType!: FilterPageType;
    @Input() fields: AoFilterHeaderField[] = [];

    @Output() filterSelected = new EventEmitter<any>();
    @Output() hiddenFilters = new EventEmitter<string[]>();

    isUnsavedFilter = false;

    filters: string[] = [];

    public hidden: string[] = [];
    public expanded = false;

    constructor(private readonly dialog: MatDialog, private readonly route: ActivatedRoute) {}

    ngOnInit(): void {
        const items = { ...localStorage };

        for (const key of Object.keys(items)) {
            if (key.startsWith(`Filter-${this.pageType}:`)) {
                this.filters.push(key.slice(8 + this.pageType.length, key.length));
            }
        }

        if (this.fields.length > 9) {
            this.getHiddenFilters();
        }
    }

    private isEmpty(): boolean {
        for (const value of Object.values(this.filter)) {
            if (value === 'null' || value === null || value === undefined || value === '') {
                continue;
            } else {
                return false;
            }
        }

        return true;
    }

    private setUnsaved(): void {
        const items = { ...localStorage };

        const filter = JSON.parse(JSON.stringify(this.filter));

        for (const [key, value] of Object.entries(items)) {
            if (key.startsWith(`Filter-${this.pageType}:`)) {
                if (JSON.stringify(filter) === value) {
                    this.isUnsavedFilter = false;
                    return;
                }
            }
        }

        this.isUnsavedFilter = true;
    }

    selectFilter(filter: string): void {
        const item = localStorage.getItem(`Filter-${this.pageType}:${filter}`);

        if (item) {
            this.filterSelected.emit(JSON.parse(item));
        }
    }

    deleteFilter({ filter, index }: { filter: string; index: number }): void {
        const dialog = this.dialog.open(DialogConfirmComponent, {
            data: {
                headline: 'Filter löschen',
                body: 'Möchten Sie den Filter wirklich löschen?',
                btn: 'Löschen',
            },
            width: '400px',
        });

        dialog.afterClosed().subscribe((res) => {
            if (res) {
                localStorage.removeItem(`Filter-${this.pageType}:${filter}`);
                this.filters.splice(index, 1);
            }
        });
    }

    private getHiddenFilters(): void {
        const item = localStorage.getItem(`Hidden-${this.pageType}`);

        if (item) {
            this.hidden = item.split(',');
        } else if (this.pageType === FilterPageType.DONATIONS) {
            this.hidden.push(...['tags', 'type', 'purposeName']);
            localStorage.setItem(`Hidden-${this.pageType}`, this.hidden.join(','));
        }

        this.hiddenFilters.emit(this.hidden);
    }

    toggleHide(name: string, hide?: boolean): void {
        const index = this.hidden.findIndex((v) => v === name);

        if (hide) {
            this.hidden.push(name);
        } else if (index !== -1) {
            if (this.fields.length - this.hidden.length === 9) {
                return;
            }

            this.hidden.splice(index, 1);
        }

        localStorage.setItem(`Hidden-${this.pageType}`, this.hidden.join(','));
        this.hiddenFilters.emit(this.hidden);
    }

    save(): void {
        const dialog = this.dialog.open(DialogAddFilterComponent, { width: '500px', maxWidth: '90vw' });

        dialog.afterClosed().subscribe((res: string) => {
            if (res) {
                localStorage.setItem(`Filter-${this.pageType}:${res}`, JSON.stringify(this.filter));
                this.isUnsavedFilter = false;
                this.filters.push(res);
            }
        });
    }
}
