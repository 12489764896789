<div class="ao-filter-list">
    <div class="header">
        <div *ngIf="showSelect" fxFlex="50px" class="left">
            <mat-checkbox
                class="checkbox-block"
                color="primary"
                (change)="onSelectAll()"
                [(ngModel)]="allSelected"
            ></mat-checkbox>
        </div>
        <div [fxFlex]="width" class="right">
            <form [formGroup]="form">
                <div class="filter-list-container" fxLayout="row" fxLayoutAlign="space-around center">
                    <div
                        [fxFlex]="(100 / visibleSpans) * (field.span ?? 1)"
                        class="field"
                        *ngFor="let field of settings.fields"
                        [class.hide]="hiddenFilters.includes(field.name)"
                    >
                        <div fxLayout="row">
                            <div class="full-width" fxFlex="auto">
                                <div [ngSwitch]="field.searchType">
                                    <div *ngSwitchCase="formOutType.STRING">
                                        <ng-container *ngIf="field.selectValues; else elseTemplate">
                                            <app-filter-select
                                                [control]="form.getFormControl(field.name)"
                                                [field]="field"
                                                [filter]="filter"
                                                (sortChange)="onSort(field)"
                                            ></app-filter-select>
                                        </ng-container>
                                        <ng-template #elseTemplate>
                                            <app-filter-input
                                                [control]="form.getFormControl(field.name)"
                                                [field]="field"
                                                (sortChange)="onSort(field)"
                                            ></app-filter-input>
                                        </ng-template>
                                    </div>
                                    <div *ngSwitchCase="formOutType.MULTI_SELECT">
                                        <app-filter-multi-select
                                            [control]="form.getFormControl(field.name)"
                                            [field]="field"
                                            [selectValues]="field.selectValues"
                                            (sortChange)="onSort(field)"
                                        ></app-filter-multi-select>
                                    </div>
                                    <div *ngSwitchCase="formOutType.INT">
                                        <app-filter-input
                                            [control]="form.getFormControl(field.name)"
                                            [field]="field"
                                            [isNumber]="true"
                                            (sortChange)="onSort(field)"
                                        ></app-filter-input>
                                    </div>
                                    <div *ngSwitchCase="formOutType.FLOAT">
                                        <app-filter-input
                                            [control]="form.getFormControl(field.name)"
                                            [field]="field"
                                            [isNumber]="true"
                                            (sortChange)="onSort(field)"
                                        ></app-filter-input>
                                    </div>
                                    <div *ngSwitchCase="formOutType.DATE_RANGE">
                                        <app-filter-date-range
                                            [form]="form"
                                            [field]="field"
                                            [filter]="filter"
                                            (sortChange)="onSort(field)"
                                            (onSort)="onSort(field)"
                                            (resetRange)="changes()"
                                        ></app-filter-date-range>
                                    </div>
                                    <div *ngSwitchCase="formOutType.AMOUNT">
                                        <app-filter-amount
                                            [field]="field"
                                            [form]="form"
                                            [filter]="filter"
                                            (sortChange)="onSort(field)"
                                        ></app-filter-amount>
                                    </div>
                                    <div
                                        class="none"
                                        fxLayout="row"
                                        fxLayoutAlign="center center"
                                        *ngSwitchCase="formOutType.NONE"
                                    >
                                        {{ field.placeholder }}
                                    </div>
                                    <div *ngSwitchDefault>{{ field.placeholder }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="showFilterSettings && pageType" fxFlex="40px" fxLayout="row" fxLayoutAlign="center center">
            <app-filter-settings
                [pageType]="pageType"
                [fields]="settings.fields"
                [filter]="filter"
                (filterSelected)="filterSelected($event)"
                (hiddenFilters)="hiddenFilters = $event; hiddenFiltersChange.emit($event)"
            ></app-filter-settings>
        </div>
    </div>
</div>
