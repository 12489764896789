import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConnectPaymentDto } from 'src/app/services/application/connect-payment.dto';
import { ErrorFormComp } from 'src/app/services/error-handler.service';

@Component({
    selector: 'app-connect-payment',
    templateUrl: './connect-payment.component.html',
    styleUrls: ['./connect-payment.component.scss'],
})
export class ConnectPaymentComponent implements ErrorFormComp {
    private _payment?: { client: string; secret: string } | undefined;
    get payment(): { client: string; secret: string } | undefined {
        return this._payment;
    }
    @Input()
    set payment(value: { client: string; secret: string } | undefined) {
        this._payment = value;
        this.connected = !!this.payment;
    }
    @Output() save = new EventEmitter<ConnectPaymentDto>();
    @Input() activeRequest = false;
    @Input() productionCheck = false;
    @Input() methodCheck = false;
    @Input() alreadyConnected = false;

    form = new UntypedFormGroup({
        client: new UntypedFormControl('', [Validators.required]),
        secret: new UntypedFormControl('', [Validators.required]),
        production: new UntypedFormControl(false, [Validators.required]),
        subscriptionMethodes: new UntypedFormControl(['card', 'sepa_debit'], [Validators.required]),
        instantMethodes: new UntypedFormControl(
            ['card', 'giropay', 'klarna', 'sepa_debit', 'sofort'],
            [Validators.required],
        ),
    });

    warning?: string;
    globalError = false;
    errorText = '';
    ignoreWarnings = false;
    connected = false;

    onSubmit(): void {
        this.warning = undefined;
        this.globalError = false;
        for (const control of Object.values(this.form.controls)) {
            control.setErrors({ incorrect: null });
            control.updateValueAndValidity();
        }
        this.form.markAllAsTouched();
        if (this.activeRequest || this.form.invalid) {
            return;
        }

        this.activeRequest = true;

        this.save.emit(this.form.value);
    }
}
