import { Component, Output, EventEmitter, Input } from '@angular/core';
import { CreateS3FileDto } from 'src/app/dtos/create-s3-file.dto';
import { S3File } from 'src/app/models/s3-file.interface';
import { DialogFileViewerComponent } from '../dialog-file-viewer/dialog-file-viewer.component';
import { Donation } from 'src/app/models/donation.interface';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-file-picker',
    templateUrl: './file-picker.component.html',
    styleUrls: ['./file-picker.component.scss'],
})
export class FilePickerComponent {
    @Input() donation!: Donation;
    @Input() files!: S3File[];
    @Input() disabled = false;

    @Output() filesChange = new EventEmitter<S3File[]>();
    @Output() createS3File = new EventEmitter<CreateS3FileDto>();

    constructor(private readonly snackBar: MatSnackBar, private readonly dialog: MatDialog) {}

    onOpenFile(file: S3File): void {
        this.dialog.open(DialogFileViewerComponent, {
            data: { donationId: this.donation._id, file },
            maxHeight: '80vh',
            maxWidth: '90vw',
        });
    }

    removeChip(index: number) {
        this.files.splice(index, 1);
        this.filesChange.emit(this.files);
    }

    onSelectFile(evt: any) {
        if (!evt.target) {
            return;
        }
        if (!evt.target.files) {
            return;
        }
        if (evt.target.files.length !== 1) {
            return;
        }
        const file = evt.target.files[0];

        const name = this.prepareName(file.name);
        const fileName = file.name;

        const fr = new FileReader();
        fr.onloadend = () => {
            const result = String(fr.result);

            if (result) {
                const size = Number(result.replace(/=/g, '').length * 0.75); // base64 to byte length
                if (size <= 10000000) {
                    // max 10mb
                    this.files.push({
                        name,
                        fileName,
                        contentType: file.type,
                        date: new Date(),
                        size: 0,
                        _id: '',
                        path: '',
                    });

                    this.createS3File.emit({
                        name,
                        fileName,
                        rawFile: result.split(',')[1],
                        contentType: file.type,
                        date: new Date(),
                        size,
                    });
                } else {
                    this.snackBar.open('Die Datei darf nicht größer als 10mb sein', 'Ok', {
                        duration: 3000,
                    });
                }
            }
        };
        fr.readAsDataURL(file);
    }

    private prepareName(fileName: string) {
        let name = fileName.substring(0, fileName.lastIndexOf('.'));
        if (name.length > 13) {
            name = fileName.substring(0, 10) + '...';
        }
        return name;
    }
}
