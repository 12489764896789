import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

@Component({
    selector: 'app-delayed-close-overlay',
    templateUrl: './delayed-close-overlay.component.html',
    styleUrls: ['./delayed-close-overlay.component.scss'],
})
export class DelayedCloseOverlayComponent implements OnInit, OnDestroy {
    @Input() activeColor = '#EB5153';
    @Input() inactiveColor = '#DCDCDC';
    @Input() icon = 'close';

    @Output() cancel = new EventEmitter<void>();
    @Output() finished = new EventEmitter<void>();

    percent = 0;
    private interval?: NodeJS.Timer;

    ngOnInit() {
        this.start();
    }

    ngOnDestroy(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    get style(): { [key: string]: string } {
        return {
            background: `linear-gradient(to top, ${this.activeColor} ${this.percent}%, transparent 0%)`,
        };
    }

    start(): void {
        this.percent = 0;
        this.interval = setInterval(() => {
            this.percent += 0.5;

            if (this.percent >= 100) {
                this.finished.emit();
                clearInterval(this.interval);
            }
        }, 10);
    }

    stop(): void {
        this.cancel.emit();
        this.percent = 0;
        clearInterval(this.interval);
    }
}
