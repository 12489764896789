import { Pipe, PipeTransform } from '@angular/core';
import { DonationType } from '../models/donation.interface';

@Pipe({
    name: 'donationType',
})
export class DonationTypePipe implements PipeTransform {
    transform(val: DonationType | undefined): string {
        switch (val) {
            case DonationType.MONEY:
                return 'Geldspende';
            case DonationType.MATERIAL:
                return 'Sachspende';
            case DonationType.ENDOWMENT:
                return 'Zustiftung';
            case DonationType.EXPENSE_WAIVER:
                return 'Aufwandsverzicht';

            default:
                return '-';
        }
    }
}
