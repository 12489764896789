import '@angular/common/locales/global/de';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NavbarComponent } from './elements/navbar/navbar.component';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './custom-date.adapter';
import * as Sentry from '@sentry/angular';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from './custom-paginator';
import { GlobalModule } from './global.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AlertRowComponent } from './elements/navbar/alert-row/alert-row.component';
import { AoAuthCompanyService, AoAuthService, AoAuthUsersService, CompanyAuthModule } from '@ah-oh/company-auth';
import { InitService } from './services/init.service';
import { CompaniesService } from './services/companies.service';
import { UsersService } from './services/users.service';
import { PermissionService } from './services/permission.service';
import { SocketIoModule } from 'ngx-socket-io';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';

@NgModule({
    declarations: [AppComponent, NavbarComponent, AlertRowComponent],
    imports: [
        GlobalModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production, // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        CompanyAuthModule.forRoot({
            api: environment.authApi,
            development: false,
            name: 'fundracer',
            additionalName: 'Spenden-Software',
            routes: {
                login: '/auth/login',
                'lost-password': '/auth/passwort-vergessen',
                'lost-password-success': '/auth/passwort-vergessen-erfolg',
                'new-password': '/auth/neues-passwort',
                'new-password-success': '/auth/neues-passwort-erfolg',
                'sign-up': '/auth/registrieren',
                'sign-up-success': '/auth/registrieren-erfolg',
                start: '/auth/login',
                'start-success': '/auth/erfolg',
                'two-factor': '/auth/2fa',
            },
        }),
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        SocketIoModule.forRoot({
            url: environment.socket,
            options: {
                path: '/event-socket',
                autoConnect: false,
            },
        }),
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (settings: InitService) => () => settings.init(),
            deps: [
                InitService,
                AoAuthService,
                AoAuthUsersService,
                AoAuthCompanyService,
                CompaniesService,
                UsersService,
                PermissionService,
            ],
            multi: true,
        },
        {
            provide: MAT_DATE_LOCALE,
            useExisting: LOCALE_ID,
        },
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
        { provide: MatPaginatorIntl, useClass: CustomPaginator },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
