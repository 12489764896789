import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
    name: 'dateToTimeSpanDays',
})
export class DateToTimeSpanDaysPipe implements PipeTransform {
    transform(value: any): string {
        if (!value) {
            return '-';
        }

        const date = dayjs(value);

        if (date.isValid()) {
            return `Vor ${dayjs().diff(date, 'day')} Tagen, ${date.format('HH.mm')} Uhr`;
        } else {
            return '-';
        }
    }
}
