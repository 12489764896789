import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { RegisterClientDto } from '../dtos/register-client.dto';

@Injectable({
    providedIn: 'root',
})
export class PushNotificationsService {
    private static readonly BASE_URL = environment.api + 'fcm';

    private readonly token: string | null = null;

    constructor(private readonly http: HttpClient, private readonly angularFireMessaging: AngularFireMessaging) {}

    // public requestPermission() {
    //     return this.angularFireMessaging.requestToken.pipe(
    //         tap((res) => {
    //             this.token = res;
    //         }),
    //     );
    // }

    removeToken() {
        if (this.token) {
            return this.angularFireMessaging.deleteToken(this.token);
        }
        return null;
    }

    receiveMessage() {
        return this.angularFireMessaging.messages;
    }

    registerClient(id: string): void {
        if (this.token) {
            const dto: RegisterClientDto = { unsubTopics: true, clientId: this.token };

            this.http.post(PushNotificationsService.BASE_URL + '/' + id, dto).subscribe();
        }
    }
}
