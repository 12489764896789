import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DriveItem } from '@microsoft/microsoft-graph-types';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CreatePrintDocumentDto } from 'src/app/models/print-documents/create-print-document.dto';
import { PrintDocument } from 'src/app/models/print-documents/print-document.interface';
import { CompaniesService } from 'src/app/services/companies.service';
import { PrintDocumentsService } from 'src/app/services/print-documents.service';
import { FormUtil, FormOutType } from 'src/app/utils/form.util';

@Component({
    selector: 'app-print-document-detail',
    templateUrl: './print-document-detail.component.html',
    styleUrls: ['./print-document-detail.component.scss'],
})
export class PrintDocumentDetailComponent implements OnInit, OnDestroy {
    @Input() id?: string;
    @Output() save = new EventEmitter<PrintDocument>();
    @Output() driveItemChange = new EventEmitter<void>();

    headline = '';
    newDocument = false;

    form = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required]),
        categoryName: new UntypedFormControl(this.id ? 'Dankeschreiben' : 'Serienbrief', [Validators.required]),
    });
    hasChanges = false;
    activeRequest = false;

    file?: DriveItem;

    document?: PrintDocument;

    pdfApiClient?: string;

    private readonly formUtil = new FormUtil(this.form);
    private readonly destroy = new Subject<void>();

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly documentsService: PrintDocumentsService,
        private readonly companiesService: CompaniesService,
    ) {}

    ngOnInit(): void {
        const id = this.id ?? this.route.snapshot.paramMap.get('id');
        this.newDocument = id === 'neu';

        this.form.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
            this.hasChanges = true;
        });

        if (!this.newDocument && id) {
            this.documentsService.findOne(id).subscribe((res) => {
                this.formUtil.setValues(res);
                this.file = res.file;
                this.document = res;

                this.headline = res.name;
            });
        } else {
            this.headline = 'Neues Dokument';
        }

        this.companiesService.findMine().subscribe((res) => {
            this.pdfApiClient = res.pdfClientId;
        });
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    close(): void {
        this.router.navigate(['/einstellungen/dokumente']);
    }

    onSubmit(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.activeRequest = true;
        this.hasChanges = false;

        const dto: CreatePrintDocumentDto = {
            name: this.formUtil.getValue({ key: 'name', outType: FormOutType.STRING }),
            categoryName: this.formUtil.getValue({ key: 'categoryName', outType: FormOutType.STRING }),
        };

        if (this.file) {
            dto.file = this.file;
            delete (<any>dto.file)['@microsoft.graph.downloadUrl'];
            delete (<any>dto.file)['@odata.context'];
        }

        if (this.document) {
            this.documentsService.update({ id: this.document._id, dto }).subscribe(
                (res: PrintDocument) => {
                    this.activeRequest = false;
                    this.save.emit(res);
                    if (!this.id) {
                        this.close();
                    }
                },
                () => {
                    this.activeRequest = false;
                },
            );
        } else {
            this.documentsService.create(dto).subscribe(
                (res: PrintDocument) => {
                    this.activeRequest = false;
                    this.save.emit(res);
                    if (!this.id) {
                        this.close();
                    }
                },
                () => {
                    this.activeRequest = false;
                },
            );
        }
    }

    onSend(): Observable<PrintDocument> {
        const dto: CreatePrintDocumentDto = {
            name: this.formUtil.getValue({ key: 'name', outType: FormOutType.STRING }),
            categoryName: this.formUtil.getValue({ key: 'categoryName', outType: FormOutType.STRING }),
        };

        if (this.file) {
            dto.file = this.file;
            delete (<any>dto.file)['@microsoft.graph.downloadUrl'];
            delete (<any>dto.file)['@odata.context'];
        }

        if (this.document) {
            return this.documentsService.update({ id: this.document._id, dto });
        } else {
            return this.documentsService.create(dto).pipe(
                tap((res) => {
                    this.document = res;
                }),
            );
        }
    }
}
