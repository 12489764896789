import { ElementRef, Injectable } from '@angular/core';
import { Donation } from '../models/donation.interface';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { UpdateDonationDto } from '../dtos/donations/update-donation.dto';
import { CreateDonationDto } from '../dtos/donations/create-donation.dto';
import { S3File } from '../models/s3-file.interface';
import { FindDonationsDto } from '../dtos/donations/find-donations.dto';
import { CountDonationsDto } from '../dtos/donations/count-donations.dto';
import { tap } from 'rxjs/operators';
import { FindEvaluationDonationsDto } from '../dtos/donations/find-evaluation-donations.dto';
import { DonationsEvaluationList } from '../models/donations-evaluation.interface';
import { DonationList } from '../dtos/donations/donation-list.interface';
import { FindDonationEntryDto } from '../dtos/donations/find-donation-entry.dto';
import { DonationEntryList } from '../models/donation-entry-list.interface';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class DonationsService {
    private static readonly BASE_URL = environment.api + 'donations';

    private readonly newPdfJobSubject = new Subject<boolean>();
    newPdfJobSub = this.newPdfJobSubject.asObservable();

    private readonly donationDeletedSubject = new Subject<void>();
    donationDeletedSub = this.donationDeletedSubject.asObservable();

    private readonly tasksFinishedSubject = new Subject<boolean>();
    tasksFinishedSub = this.tasksFinishedSubject.asObservable();
    private readonly tasksFinishedMap: Map<string, boolean> = new Map();

    private readonly setPortal = new Subject<ElementRef<HTMLElement>>();
    $setPortal = this.setPortal.asObservable();

    private readonly disableDonation = new Subject<{ disable: boolean; donation: Donation }>();
    $disableDonation = this.disableDonation.asObservable();

    constructor(private readonly http: HttpClient, private readonly snackBar: MatSnackBar) {
        this.newPdfJobSub.subscribe((print) => {
            this.snackBar.open(`Der ${print ? 'Druck' : 'PDF'}-Auftrag wurde erfolgreich eingereiht`, '', {
                duration: 4000,
            });
        });
    }

    setPortalSection(val: ElementRef<HTMLElement>): void {
        this.setPortal.next(val);
    }

    setDisableDonation({ disable, donation }: { disable: boolean; donation: Donation }): void {
        this.disableDonation.next({ disable, donation });
    }

    setFinishedTasks({ type, value }: { type: string; value: boolean }): void {
        this.tasksFinishedMap.set(type, value);

        this.tasksFinishedSubject.next([...this.tasksFinishedMap.values()].every((v) => v));
    }

    findFile({ donationId, fileId }: { donationId: string; fileId: string }): Observable<S3File> {
        return this.http.get<S3File>(`${DonationsService.BASE_URL}/${donationId}/file/${fileId}`);
    }
    findOne(id: string): Observable<Donation> {
        return this.http.get<Donation>(DonationsService.BASE_URL + '/' + id);
    }

    importAltruja(id: string): Observable<{ count: number }> {
        return this.http.get<{ count: number }>(DonationsService.BASE_URL + '/import/altruja/' + id);
    }

    search(name: string): Observable<Donation[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('name', name);
        return this.http.get<Donation[]>(`${DonationsService.BASE_URL}/search`, { params });
    }
    findEvaluation(dto: FindEvaluationDonationsDto): Observable<DonationsEvaluationList> {
        let params: HttpParams = new HttpParams();
        for (const [key, value] of Object.entries(dto)) {
            if (value !== undefined) {
                params = params.append(key, String(value));
            }
        }
        return this.http.get<DonationsEvaluationList>(`${DonationsService.BASE_URL}/evaluation`, { params });
    }

    createEvaluationCvs({ ids, password }: { ids: string[]; password: string }): Observable<any> {
        return this.http.post(
            `${DonationsService.BASE_URL}/evaluation-export`,
            { ids, password },
            {
                responseType: 'blob',
                observe: 'response',
            },
        );
    }

    find(dto: FindDonationsDto): Observable<DonationList> {
        let params: HttpParams = new HttpParams();
        for (const [key, value] of Object.entries(dto)) {
            if (value !== undefined) {
                params = params.append(key, String(value));
            }
        }
        return this.http.get<DonationList>(`${DonationsService.BASE_URL}`, { params });
    }

    findEntry(dto: FindDonationEntryDto): Observable<DonationEntryList> {
        let params: HttpParams = new HttpParams();
        for (const [key, value] of Object.entries(dto)) {
            if (value !== undefined) {
                params = params.append(key, String(value));
            }
        }
        return this.http.get<DonationEntryList>(`${DonationsService.BASE_URL}/entry`, { params });
    }

    count(dto: CountDonationsDto): Observable<number> {
        let params: HttpParams = new HttpParams();
        for (const [key, value] of Object.entries(dto)) {
            if (value !== undefined) {
                params = params.append(key, String(value));
            }
        }
        return this.http.get<number>(`${DonationsService.BASE_URL}/count`, { params });
    }

    update({ id, dto }: { id: string; dto: UpdateDonationDto }): Observable<Donation> {
        return this.http.post<Donation>(DonationsService.BASE_URL + '/' + id, dto);
    }

    create(dto: CreateDonationDto): Observable<Donation> {
        return this.http.put<Donation>(DonationsService.BASE_URL, dto);
    }

    createFromCsv(dtos: CreateDonationDto[]): Observable<Donation[]> {
        return this.http.put<Donation[]>(DonationsService.BASE_URL + '/csv', dtos);
    }

    deleteOne(id: string): Observable<Donation> {
        return this.http.delete<Donation>(DonationsService.BASE_URL + '/' + id).pipe(
            tap(() => {
                this.donationDeletedSubject.next();
            }),
        );
    }

    delete(ids: string[]): Observable<any> {
        let params: HttpParams = new HttpParams();
        params = params.append('ids', ids.join(','));
        return this.http.delete<Donation>(DonationsService.BASE_URL, { params });
    }

    createPdf({ ids, print, mergeDonator }: { ids: string[]; print: boolean; mergeDonator: boolean }): Observable<any> {
        return this.http.post(`${DonationsService.BASE_URL}/pdf`, { ids, mergeDonator, print }).pipe(
            tap(() => {
                this.newPdfJobSubject.next(print);
            }),
        );
    }

    createSinglePdf({
        id,
        print,
        mergeDonator,
    }: {
        id: string;
        print: boolean;
        mergeDonator: boolean;
    }): Observable<any> {
        return this.http.post(`${DonationsService.BASE_URL}/pdf`, { ids: [id], mergeDonator, print });
    }

    createPrinterPdf(ids: string[]): Observable<any> {
        return this.http.post(`${DonationsService.BASE_URL}/pdf-printer`, { ids }).pipe(
            tap(() => {
                this.newPdfJobSubject.next(true);
            }),
        );
    }
}
