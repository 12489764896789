import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface NavLink {
    label: string;
    path: string;
    params?: { [key: string]: string };
    exact?: boolean;
}

@Component({
    selector: 'app-second-header',
    templateUrl: './second-header.component.html',
    styleUrls: ['./second-header.component.scss'],
})
export class SecondHeaderComponent {
    @Input() headline?: string;
    @Input() searchBar?: boolean;
    @Input() navLinks?: NavLink[];
    @Input() hasSelect = false;
    @Input() selectText = 'Deaktivierte einblenden';
    @Input() backLink? = '';
    @Input() showButton = false;

    @Output() search = new EventEmitter<string>();
    @Output() selectChange = new EventEmitter<boolean>();
    @Output() back = new EventEmitter<void>();

    activeMobileSearch = false;
    archived = false;
    searchString = '';
}
