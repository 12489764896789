<div class="headline">
    <h2>Filter speichern</h2>
    <button mat-icon-button (click)="dialogRef.close(false)">
        <mat-icon>clear</mat-icon>
    </button>
</div>

<div class="body">
    <div class="margin">
        Bitte geben Sie der gesetzten Filtereinstellung einen Namen, um sie später wiederzufinden. Geben Sie den Filter
        bei Bedarf für andere Benutzer frei.
    </div>

    <mat-form-field appearance="fill" class="full-width slim-field">
        <mat-label>Name</mat-label>
        <input type="text" matInput [formControl]="name" />
        <mat-error *ngIf="name.getError('required')"> Pflichtfeld </mat-error>
    </mat-form-field>
</div>
<div class="footer">
    <button class="full-width" (click)="save()" [disabled]="name.invalid" mat-flat-button color="primary">
        <mat-icon>save</mat-icon> Speichern
    </button>
</div>
