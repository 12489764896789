import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-unlock',
    templateUrl: './unlock.component.html',
    styleUrls: ['./unlock.component.scss'],
})
export class UnlockComponent implements OnInit, AfterViewInit {
    @Input() big?: boolean = true;
    @Input() text?: string;
    @Input() id?: string;
    @Input() lockedBackgroundColor? = '#ff3838';
    @Input() unlockedBackgroundColor? = '#ff3838';

    @Output() locked = new EventEmitter();
    @Output() unlocked = new EventEmitter();

    @ViewChild('slider') slider!: ElementRef;

    lockedIcon =
        '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="white"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"/></svg>';
    unlockedIcon =
        '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="white"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>';

    unlockStyle?: boolean;

    thumbStyle?: Element | null;

    value = 0;
    maxValue = 150; // the higher the smoother when dragging

    private isUnlocked?: boolean;
    private readonly speed = 12;
    private currValue = 0;
    private rafID?: number;

    ngOnInit() {
        this.isUnlocked = false;
        this.unlockStyle = false;
        const head = document.getElementsByTagName('head')[0];
        this.thumbStyle = document.createElement('style');
        head.appendChild(this.thumbStyle);
        this.setData();
    }

    ngAfterViewInit() {
        if (this.id != undefined) {
            this.slider.nativeElement.classList.add(this.id);
        }
    }

    setState(state: 'locked' | 'unlocked') {
        this.isUnlocked = state === 'unlocked';
        this.unlockStyle = state === 'unlocked';
        if (state === 'locked') {
            this.value = 0;
        } else {
            this.value = this.maxValue;
        }
    }

    unlockStartHandler(): void {
        // clear raf if trying again
        if (this.rafID) {
            window.cancelAnimationFrame(this.rafID);
        }

        // set to desired value
        this.currValue = +this.value;
    }

    unlockEndHandler(): void {
        // store current value
        this.currValue = +this.value;
        // determine if we have reached success or not
        if (this.currValue >= this.maxValue && !this.isUnlocked) {
            this.unlockedHandler();
        } else if (this.currValue <= 0 && this.isUnlocked) {
            this.lockedHandler();
        } else if (!this.isUnlocked) {
            this.rafID = window.requestAnimationFrame(this.animateLockedHandler);
        } else {
            this.rafID = window.requestAnimationFrame(this.animateUnlockedHandler);
        }
    }

    animateLockedHandler = () => {
        // update input range
        this.value = this.currValue;

        // determine if we need to continue
        if (this.currValue > -1) {
            window.requestAnimationFrame(this.animateLockedHandler);
        }

        // decrement value
        this.currValue -= this.speed;
    };

    animateUnlockedHandler = () => {
        // update input range
        this.value = this.currValue;

        // determine if we need to continue
        if (this.currValue < this.maxValue) {
            window.requestAnimationFrame(this.animateUnlockedHandler);
        }

        // decrement value
        this.currValue += this.speed;
    };

    private unlockedHandler(): void {
        this.unlockStyle = true;
        this.isUnlocked = true;
        this.unlocked.emit();
        this.setData();
    }

    private lockedHandler(): void {
        this.unlockStyle = false;
        this.isUnlocked = false;
        this.locked.emit();
        this.setData();
    }

    private setData() {
        if (this.thumbStyle) {
            if (this.big) {
                if (this.isUnlocked) {
                    this.thumbStyle.innerHTML =
                        (this.id != undefined ? '.' + this.id + ' ' : '') +
                        '.pullee::-webkit-slider-thumb { background-color: ' +
                        this.unlockedBackgroundColor +
                        "; background-image: url('data:image/svg+xml;utf-8," +
                        this.unlockedIcon +
                        "'); width: 40px; height: 40px;}" +
                        (this.id != undefined ? '.' + this.id + ' ' : '') +
                        '.pullee::-moz-range-thumb { background-color: ' +
                        this.unlockedBackgroundColor +
                        "; background-image: url('data:image/svg+xml;utf-8," +
                        this.unlockedIcon +
                        "');  width: 40px; height: 40px;}" +
                        (this.id != undefined ? '.' + this.id + ' ' : '') +
                        '.pullee::-ms-thumb { background-color: ' +
                        this.unlockedBackgroundColor +
                        "; background-image: url('data:image/svg+xml;utf-8," +
                        this.unlockedIcon +
                        "');  width: 40px; height: 40px;}";
                } else {
                    this.thumbStyle.innerHTML =
                        (this.id != undefined ? '.' + this.id + ' ' : '') +
                        '.pullee::-webkit-slider-thumb { background-color: ' +
                        this.lockedBackgroundColor +
                        "; background-image: url('data:image/svg+xml;utf-8," +
                        this.lockedIcon +
                        "'); width: 40px; height: 40px;}" +
                        (this.id != undefined ? '.' + this.id + ' ' : '') +
                        '.pullee::-moz-range-thumb { background-color: ' +
                        this.lockedBackgroundColor +
                        "; background-image: url('data:image/svg+xml;utf-8," +
                        this.lockedIcon +
                        "');  width: 40px; height: 40px;}" +
                        (this.id != undefined ? '.' + this.id + ' ' : '') +
                        '.pullee::-ms-thumb { background-color: ' +
                        this.lockedBackgroundColor +
                        "; background-image: url('data:image/svg+xml;utf-8," +
                        this.lockedIcon +
                        "');  width: 40px; height: 40px;}";
                }
            } else {
                if (this.isUnlocked) {
                    this.thumbStyle.innerHTML =
                        (this.id != undefined ? '.' + this.id + ' ' : '') +
                        '.pullee::-webkit-slider-thumb { background-color: ' +
                        this.unlockedBackgroundColor +
                        "; background-image: url('data:image/svg+xml;utf-8," +
                        this.unlockedIcon +
                        "'); width: 25px; height: 25px;}" +
                        (this.id != undefined ? '.' + this.id + ' ' : '') +
                        '.pullee::-moz-range-thumb { background-color: ' +
                        this.unlockedBackgroundColor +
                        "; background-image: url('data:image/svg+xml;utf-8," +
                        this.unlockedIcon +
                        "'); width: 25px; height: 25px;}" +
                        (this.id != undefined ? '.' + this.id + ' ' : '') +
                        '.pullee::-ms-thumb { background-color: ' +
                        this.unlockedBackgroundColor +
                        "; background-image: url('data:image/svg+xml;utf-8," +
                        this.unlockedIcon +
                        "'); width: 25px; height: 25px;}";
                } else {
                    this.thumbStyle.innerHTML =
                        (this.id != undefined ? '.' + this.id + ' ' : '') +
                        '.pullee::-webkit-slider-thumb { background-color: ' +
                        this.lockedBackgroundColor +
                        "; background-image: url('data:image/svg+xml;utf-8," +
                        this.lockedIcon +
                        "'); width: 25px; height: 25px;}" +
                        (this.id != undefined ? '.' + this.id + ' ' : '') +
                        '.pullee::-moz-range-thumb { background-color: ' +
                        this.lockedBackgroundColor +
                        "; background-image: url('data:image/svg+xml;utf-8," +
                        this.lockedIcon +
                        "'); width: 25px; height: 25px;}" +
                        (this.id != undefined ? '.' + this.id + ' ' : '') +
                        '.pullee::-ms-thumb { background-color: ' +
                        this.lockedBackgroundColor +
                        "; background-image: url('data:image/svg+xml;utf-8," +
                        this.lockedIcon +
                        "'); width: 25px; height: 25px;}";
                }
            }
        }
    }
}
