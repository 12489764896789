<section fxLayout="row">
    <div
        class="picker"
        [class.has-name]="!!name"
        [style.height]="height"
        [style.width]="height"
        [style.background]="colorInput"
        [(colorPicker)]="colorInput"
        (colorPickerClose)="changeColor()"
        [cpOKButton]="true"
        mat-ripple
    ></div>

    <div *ngIf="name" class="name">{{ name }}</div>
</section>
