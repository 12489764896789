<div class="headline">
    <h2>{{ data.file.name }}</h2>
    <button mat-icon-button (click)="dialogRef.close(false)">
        <mat-icon>clear</mat-icon>
    </button>
</div>

<div class="body">
    <div *ngIf="!imgSrc && !pdfSrc" class="loader-container">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="imgSrc" class="img-container">
        <img [src]="imgSrc" />
    </div>
    <div *ngIf="pdfSrc" class="pdf-container">
        <pdf-viewer [src]="pdfSrc" [original-size]="false"></pdf-viewer>
    </div>
</div>
<div class="footer">
    <button [disabled]="rawFile === null" mat-button (click)="downloadFile()">Download</button>
</div>
