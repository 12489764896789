import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-save-btn',
    templateUrl: './save-btn.component.html',
    styleUrls: ['./save-btn.component.scss'],
})
export class SaveBtnComponent {
    private _hasChanges = false;
    get hasChanges() {
        return this._hasChanges;
    }
    @Input()
    set hasChanges(value) {
        this._hasChanges = value;
        if (value) {
            this.btnText = 'Speichern';
        } else {
            this.btnText = 'Gespeichert';
        }
    }

    private _isLoading = false;
    get isLoading() {
        return this._isLoading;
    }
    @Input()
    set isLoading(value) {
        this._isLoading = value;
        if (value) {
            this.btnText = 'Speichern';
        } else {
            this.btnText = 'Gespeichert';
        }
    }

    @Output() save = new EventEmitter();

    btnText = 'Gespeichert';

    get icon(): string {
        if (this.hasChanges && !this.isLoading) {
            return 'save';
        }
        if (this.isLoading) {
            return 'autorenew';
        }
        return 'download_done';
    }

    onSave(): void {
        this.save.emit();
    }
}
