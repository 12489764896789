<div class="auto-save" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!hasChanges && !wasReset && !lastUpdated">
        <mat-icon>download_done</mat-icon> Gespeichert
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="hasChanges">
        <mat-icon class="spin">autorenew</mat-icon> Änderung wird gespeichert...
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!hasChanges && wasReset">
        Version von {{ lastSaved }} Uhr wiederhergestellt
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="lastUpdated">Zuletzte aktualisiert {{ lastUpdated }}</div>

    <a *ngIf="!lastUpdated && !wasReset" (click)="reset()">Letzte Änderungen zurücksetzen</a>
</div>
