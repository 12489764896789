export const environment = {
    production: true,
    url: 'https://my.fundracer.de',
    api: '/api/',
    socket: '',
    pdfApi: '/pdf-api/',
    authApi: '/auth-api/public',
    googleApiKey: 'AIzaSyAsQ_Ogx_e5AbI58eMqoOz8m9sqWM6cgeg',
    sentry: 'https://66745b7e1e414f689663dab7a7d51694@o400738.ingest.sentry.io/6240645',
    firebase: {
        apiKey: 'AIzaSyD1bfnijoK2A3e6btyBerWMzCaEf0yt4AM',
        authDomain: 'spenden-so.firebaseapp.com',
        databaseURL: 'https://spenden-so.firebaseio.com',
        projectId: 'spenden-so',
        storageBucket: 'spenden-so.appspot.com',
        messagingSenderId: '1011825806388',
        appId: '1:1011825806388:web:660b4d5488b2ce82440e61',
    },
    froalaKey: 'vYA6mA5C5F4D4C2A10A8eMRPYf1h1REb1BGQOQIc2CDBREJImA11C8D6D5B1G4A2F2I3C9==',
};
