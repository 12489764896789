import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface CustomBtnEle {
    name: string;
    value: string;
}

@Component({
    selector: 'app-custom-btn-group',
    templateUrl: './custom-btn-group.component.html',
    styleUrls: ['./custom-btn-group.component.scss'],
})
export class CustomBtnGroupComponent {
    @Input() elements!: CustomBtnEle[];
    @Input() groupControl!: UntypedFormControl;

    get selectedValues(): string[] {
        return Array.isArray(this.groupControl.value) ? this.groupControl.value : [];
    }

    toggleBtn(value: string): void {
        const values = this.selectedValues.slice();
        const index = values.indexOf(value);
        if (index !== -1) {
            values.splice(index, 1);
        } else {
            values.push(value);
        }
        this.groupControl.setValue(values);
    }
}
