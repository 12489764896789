<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <h2 *ngIf="!pdfJobId">PDF wird generiert.</h2>
    <h2 *ngIf="pdfJobId">PDF erstellt.</h2>

    <button mat-icon-button (click)="dialogRef.close(false)">
        <mat-icon>clear</mat-icon>
    </button>
</div>

<div>
    <div *ngIf="!pdfJobId" style="margin-bottom: 20px">Bitte warten Sie einen Moment. Ihre PDF wird generiert.</div>

    <div *ngIf="pdfJobId" style="margin-bottom: 20px">
        Ihre PDF wurde erstellt und kann über den Download-Button oder im Downloadfenster von Fundracer heruntergeladen
        werden.
    </div>

    <div style="padding: 32px" fxLayout="row" fxLayoutAlign="center center">
        <div *ngIf="!pdfJobId"><mat-spinner></mat-spinner></div>
        <div *ngIf="pdfJobId">
            <mat-icon class="done">check_circle</mat-icon>
        </div>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="end center">
    <button class="full-width" (click)="download()" mat-flat-button color="primary" [disabled]="!pdfJobId">
        <mat-icon>file_download</mat-icon> Download
    </button>
</div>
