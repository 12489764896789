import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { DialogUpdatesAvailableComponent } from '../elements/dialog-updates-available/dialog-updates-available.component';

@Injectable({
    providedIn: 'root',
})
export class UpdatesService {
    currentHash?: string;

    constructor(
        private readonly updates: SwUpdate,
        private readonly dialog: MatDialog,
        private readonly httpClient: HttpClient,
    ) {
        updates.versionUpdates.subscribe((evt) => {
            switch (evt.type) {
                case 'VERSION_DETECTED':
                    console.log(`Downloading new app version: ${evt.version.hash}`);
                    break;
                case 'VERSION_READY':
                    console.log(`Current app version: ${evt.currentVersion.hash}`);
                    console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
                    this.httpClient
                        .get<any[]>('https://my.sethub.de/api/data-objects/6234590ad822f29f51b87ae0/public')
                        .subscribe((res: any[]) => {
                            this.dialog
                                .open(DialogUpdatesAvailableComponent, {
                                    data: {
                                        description: res[res.length - 1]?.content.description ?? 'Diverse Bugfixes',
                                    },
                                })
                                .afterClosed()
                                .subscribe((shouldUpdate: boolean) => {
                                    if (shouldUpdate) {
                                        this.updates.activateUpdate().then(() => document.location.reload());
                                    }
                                });
                        });
                    break;
                case 'VERSION_INSTALLATION_FAILED':
                    console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                    break;
            }
        });
    }
}
