import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SearchTagsDto, TagsDto } from '../dtos/tags.dto';
import { Tag } from '../models/tags.model';

@Injectable({
    providedIn: 'root',
})
export class TagsService {
    private static readonly BASE_URL = environment.api + 'tags';

    constructor(private readonly http: HttpClient) {}

    find(dto: SearchTagsDto): Observable<Tag[]> {
        let params: HttpParams = new HttpParams();
        for (const [key, value] of Object.entries(dto)) {
            if (value !== undefined) {
                params = params.append(key, String(value));
            }
        }

        return this.http.get<Tag[]>(TagsService.BASE_URL, { params });
    }

    create(dto: TagsDto): Observable<Tag> {
        return this.http.put<Tag>(TagsService.BASE_URL, dto);
    }

    update({ id, dto }: { id: string; dto: TagsDto }): Observable<Tag> {
        return this.http.post<Tag>(TagsService.BASE_URL + '/' + id, dto);
    }

    delete(id: string): Observable<Tag> {
        return this.http.delete<Tag>(TagsService.BASE_URL + '/' + id);
    }
}
