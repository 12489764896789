import { Pipe, PipeTransform } from '@angular/core';
import { DonatorFormatUtil, DonatorLike } from '../utils/donator-format.util';

@Pipe({
    name: 'donatorName',
})
export class DonatorNamePipe implements PipeTransform {
    transform(donator: DonatorLike | string | undefined): string {
        return DonatorFormatUtil.formatDonatorName(donator);
    }
}
