import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { User, EvaluationFilter } from '../models/user.interface';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { first, Observable, Subject, tap } from 'rxjs';
import { UpdateUserDto } from '../dtos/update-user.dto';
import { CreateUserDto } from '../dtos/create-user.dto';
import { UpdateUserPasswordDto } from '../dtos/update-user-password.dto';
import { ChangeCompanyDto } from '../dtos/change-company.dto';
import { AddEvaluationFilterDto } from '../dtos/add-evaluation-filter.dto';
import { AoAuthService, AoAuthUser } from '@ah-oh/company-auth';
import { FindUsersDto } from '../dtos/find-users.dto';
import { Socket } from 'ngx-socket-io';
import { Router } from '@angular/router';
import { Company } from '../models/company.interface';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    private static readonly BASE_URL = environment.api + 'users';

    private readonly userChange = new Subject<(AoAuthUser & User) | undefined>();
    $userChange = this.userChange.asObservable();

    myUser?: AoAuthUser & User;

    private socketConnected = false;
    stable = false;

    constructor(
        private readonly http: HttpClient,
        private readonly authService: AoAuthService,
        private readonly socket: Socket,
        private readonly router: Router,
        private readonly appRef: ApplicationRef,
        private readonly ngZone: NgZone,
    ) {
        this.appRef.isStable.pipe(first((stable) => stable)).subscribe(() => {
            this.ngZone.run(() => {
                const checkStatus = (status: boolean) => {
                    if (status) {
                        if (!this.myUser) {
                            this.findMine().subscribe();
                        }

                        if (this.authService.signedInRes && !this.socketConnected) {
                            this.socket.ioSocket.io.opts.query = {
                                token: this.authService.signedInRes.token,
                            };
                            this.socket.connect();
                            this.socketConnected = true;
                        }
                    } else {
                        this.setMyUser(undefined);

                        if (this.socketConnected) {
                            this.socket.disconnect();
                            this.socketConnected = false;
                        }
                    }
                };

                checkStatus(this.authService.isSignedIn);

                authService.$signIn.subscribe(checkStatus);
            });
        });
    }

    get companyId(): string | undefined {
        if (!this.myUser) {
            return undefined;
        }
        if (typeof this.myUser.company === 'string') {
            return this.myUser.company;
        }
        return (<Company>this.myUser.company)._id;
    }

    findMine(): Observable<AoAuthUser & User> {
        return this.http.get<AoAuthUser & User>(`${UsersService.BASE_URL}/me`).pipe(
            tap((res: AoAuthUser & User) => {
                this.setMyUser(res);
            }),
        );
    }

    setMyUser(user: (AoAuthUser & User) | undefined): void {
        this.myUser = user;
        this.userChange.next(this.myUser);
        if (this.myUser?.archived) {
            this.authService.signOut().subscribe(() => {
                this.router.navigate(['/auth/login']);
            });
        }
    }

    findAll(dto: FindUsersDto = {}): Observable<(AoAuthUser & { archived: boolean | undefined })[]> {
        let params: HttpParams = new HttpParams();
        if (dto.archived !== undefined) params = params.append('archived', dto.archived);

        return this.http.get<(AoAuthUser & { archived: boolean | undefined })[]>(UsersService.BASE_URL, { params });
    }

    findOne(id: string): Observable<User> {
        return this.http.get<User>(UsersService.BASE_URL + '/' + id);
    }

    update({ id, dto }: { id: string; dto: UpdateUserDto }): Observable<User> {
        return this.http.post<User>(UsersService.BASE_URL + '/' + id, dto);
    }

    create(dto: CreateUserDto): Observable<User> {
        return this.http.put<User>(UsersService.BASE_URL, dto);
    }

    updatePassword(dto: UpdateUserPasswordDto): Observable<User> {
        return this.http.post<User>(UsersService.BASE_URL + '/password', dto);
    }

    updateLostPassword(dto: UpdateUserPasswordDto): Observable<User> {
        return this.http.post<User>(UsersService.BASE_URL + '/lost-password', dto);
    }

    changeCompany(dto: ChangeCompanyDto): Observable<User> {
        return this.http.post<User>(UsersService.BASE_URL + '/company', dto);
    }

    resetPassword(id: string): Observable<User> {
        return this.http.get<User>(UsersService.BASE_URL + '/' + id + '/password');
    }

    archive(id: string): Observable<User> {
        return this.http.delete<User>(UsersService.BASE_URL + '/' + id);
    }

    reactivate(id: string): Observable<AoAuthUser & User> {
        return this.http.get<AoAuthUser & User>(`${UsersService.BASE_URL}/${id}/reactivate`);
    }

    addEvaluationFilter(dto: AddEvaluationFilterDto): Observable<EvaluationFilter[]> {
        return this.http.put<EvaluationFilter[]>(`${UsersService.BASE_URL}/evaluation-filter`, dto);
    }

    findEvaluationFilters(): Observable<EvaluationFilter[]> {
        return this.http.get<EvaluationFilter[]>(`${UsersService.BASE_URL}/evaluation-filter`);
    }

    deleteEvaluationFilter(index: number): Observable<EvaluationFilter[]> {
        return this.http.delete<EvaluationFilter[]>(`${UsersService.BASE_URL}/evaluation-filter/${index}`);
    }
}
