<mat-form-field class="full-width slim-field tag-chips" appearance="fill">
    <mat-label>Tags</mat-label>
    <mat-chip-grid #chipList>
        <mat-chip-option
            *ngFor="let tag of tags ?? []; index as i"
            [selectable]="false"
            [removable]="true"
            [style.backgroundColor]="tag.color"
            (removed)="removeTag(tag)"
        >
            <span>{{ tag.name }}</span>
            <mat-icon style="color: white" matChipRemove>close</mat-icon>
        </mat-chip-option>
        <input
            #input
            [formControl]="control"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputAddOnBlur]="true"
        />
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
        <mat-option *ngFor="let content of filteredTags | async" [value]="content">
            {{ content.name }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
