<div *ngIf="jobMap.size" (click)="$event.stopPropagation()" style="padding: 16px">
    <h2>Downloads</h2>
    <div class="line"></div>

    <div class="jobs">
        <div class="item" *ngFor="let item of jobMap | keyvalue : sort">
            <h2 class="margin">{{ item.key }}</h2>

            <div class="job" [class.downloaded]="job.downloaded" *ngFor="let job of item.value">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                        <div class="time">{{ job.createdAt | dateToTime }}</div>
                        <mat-icon fxFlex="24px" class="file">picture_as_pdf</mat-icon>
                        <div class="name">{{ job.name }}</div>
                    </div>
                    <div>
                        <div
                            *ngIf="job.status === 'finished'"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxLayoutGap="20px"
                        >
                            <button (click)="download.emit(job); $event.stopPropagation()" mat-icon-button>
                                <mat-icon>save_alt</mat-icon>
                            </button>
                            <button (click)="delete.emit(job); $event.stopPropagation()" mat-icon-button>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                        <div *ngIf="job.status !== 'finished'">
                            <div class="error" *ngIf="job.status === 'error'">Error</div>
                            <div *ngIf="job.status !== 'error'">
                                <app-pdf-progress [job]="job"></app-pdf-progress>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
            </div>
        </div>
    </div>
</div>
