<div class="alert-row">
    <div class="alert-date">
        {{ alert.updatedAt | dateToDateTimeString }}
    </div>
    <div fxLayout="row">
        <div fxFlex="auto">
            <span [ngSwitch]="alert.type">
                <div *ngSwitchCase="alertType.ALTRUJA_DEFAULT">
                    <b
                        ><a routerLink="/einstellungen/kanaele"
                            >Ein Altruja-Kanal konnte nicht aktualisiert werden, bitte überprüfen Sie die
                            Zugangsdaten.</a
                        ></b
                    >
                </div>
                <div *ngSwitchCase="alertType.ALTRUJA_UNAUTHORIZED">
                    <b
                        ><a routerLink="/einstellungen/kanaele"
                            >Ein Altruja-Kanal konnte nicht aktualisiert werden, bitte überprüfen Sie die
                            Zugangsdaten.</a
                        ></b
                    >
                </div>
                <div *ngSwitchCase="alertType.FIN_API_DEFAULT">
                    <b
                        ><a routerLink="/einstellungen/kanaele">
                            {{
                                alert.message
                                    ? alert.message
                                    : 'Ein Fin-API-Kanal konnte nicht aktualisiert werden, bitte verbinden Sie die Konten neu.'
                            }}
                        </a></b
                    >
                </div>
            </span>
        </div>
        <div fxFlex="50px">
            <app-delayed-button [icon]="'visibility_off'" (finished)="hide.emit(alert)"></app-delayed-button>
        </div>
    </div>
</div>
