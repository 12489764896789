import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AoFilterHeaderField } from '../ao-filter-list-header.component';

@Component({
    selector: 'app-filter-input',
    templateUrl: './filter-input.component.html',
    styleUrls: ['./filter-input.component.scss'],
})
export class FilterInputComponent {
    @Input() field!: AoFilterHeaderField;
    @Input() control!: UntypedFormControl;
    @Input() isNumber = false;

    @Output() sortChange = new EventEmitter<void>();

    public get sortable() {
        return !!this.field.sortKey;
    }
}
