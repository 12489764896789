import { ApplicationRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subscription, fromEvent, merge } from 'rxjs';
import { LoaderService, LoaderState } from '../../services/loader.service';
import { Router, NavigationEnd } from '@angular/router';
import { debounceTime, filter, first, takeUntil } from 'rxjs/operators';
import { PushNotificationsService } from 'src/app/services/push-notifications.service';
import { DonationsService } from 'src/app/services/donations.service';
import { PrintDocumentsService } from 'src/app/services/print-documents.service';
import { PageLifecycleService } from 'src/app/services/page-lifecycle.service';
import { Alert } from 'src/app/models/alert.interface';
import { AlertsService } from 'src/app/services/alert.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { environment } from 'src/environments/environment';
import { AoAuthService, AoAuthUser } from '@ah-oh/company-auth';
import { PermissionService } from 'src/app/services/permission.service';
import dayjs from 'dayjs';
import { User } from '@sentry/angular';
import { UsersService } from 'src/app/services/users.service';
import { Subject } from 'rxjs';
import { PdfJob, PdfJobService } from '@ah-oh/ng-pdf-api';
import { NavLink } from '../second-header/second-header.component';
import { Socket } from 'ngx-socket-io';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    activeRequest = false;

    activeNav = false;
    oldTopPosition = 0;

    showNavigation = false;

    tasksFinished = true;

    unSeenJobs = false;
    pdfJobs: PdfJob[] = [];

    alerts: Alert[] = [];

    pdfApiClient?: string;
    pdfApi = environment.pdfApi;
    pdfMenuSeen = true;

    navLinks: NavLink[] = [];
    seeSideNav = false;

    private activeDownload = false;
    private loaderSubscription?: Subscription;

    user?: (AoAuthUser & User) | undefined;

    private readonly destroy = new Subject<void>();

    constructor(
        private readonly loaderService: LoaderService,
        private readonly authService: AoAuthService,
        private readonly router: Router,
        private readonly pushNotificationsService: PushNotificationsService,
        private readonly donationsService: DonationsService,
        private readonly pageLifecycleService: PageLifecycleService,
        private readonly usersService: UsersService,
        private readonly alertsService: AlertsService,
        private readonly companiesService: CompaniesService,
        public readonly permission: PermissionService,
        private readonly pdfJobsService: PdfJobService,
        private readonly appRef: ApplicationRef,
        private readonly zone: NgZone,
        private readonly printDocumentsService: PrintDocumentsService,
        private readonly socket: Socket,
    ) {}

    ngOnInit() {
        this.appRef.isStable.pipe(first((stable) => stable)).subscribe(() =>
            this.zone.run(() => {
                this.loaderSubscription = this.loaderService.loaderState.subscribe((res: LoaderState) => {
                    this.activeRequest = res.show;
                });

                if (this.usersService.myUser) {
                    this.user = this.usersService.myUser;
                }

                this.usersService.stable = true;

                this.setNav();
                this.getAlerts();
                this.getPdfApi();

                this.usersService.$userChange.subscribe((user) => {
                    this.user = user;
                    this.setNav();
                    this.getAlerts();
                    this.getPdfApi();
                });

                fromEvent(window, 'scroll').subscribe(() => {
                    const newTopPosition = window.pageYOffset;
                    this.activeNav = newTopPosition < this.oldTopPosition && newTopPosition !== 0;
                    this.oldTopPosition = newTopPosition;
                });

                this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
                    this.showNavigation = false;
                });

                merge(
                    this.authService.$signIn,
                    this.donationsService.newPdfJobSub,
                    this.pageLifecycleService.visibilityChange,
                    this.pageLifecycleService.resume,
                ).subscribe(() => {
                    this.getAlerts();
                });

                this.authService.$signIn.subscribe((res) => {
                    if (res) {
                        this.getPdfApi();
                    }
                });

                this.permission.$permissionChange.subscribe(() => {
                    this.setNav();
                });

                this.donationsService.tasksFinishedSub
                    .pipe(takeUntil(this.destroy), debounceTime(500))
                    .subscribe((res: boolean) => {
                        this.tasksFinished = res;
                    });

                this.donationsService.newPdfJobSub.pipe(takeUntil(this.destroy)).subscribe(() => {
                    this.pdfMenuSeen = false;
                    this.getPdfJobs();
                });

                this.printDocumentsService.newPdfJobSub.pipe(takeUntil(this.destroy)).subscribe(() => {
                    this.pdfMenuSeen = false;
                    this.getPdfJobs();
                });

                this.socket
                    .fromEvent('new-pdf')
                    .pipe(takeUntil(this.destroy))
                    .subscribe(() => {
                        this.pdfMenuSeen = false;
                        this.getPdfJobs();
                    });

                this.getPdfJobs();
            }),
        );
    }

    ngOnDestroy(): void {
        this.loaderSubscription?.unsubscribe();
        this.destroy.next();
        this.destroy.complete();
    }

    get isSignedIn(): boolean {
        return this.authService.isSignedIn;
    }

    getAlerts(): void {
        if (this.authService.isSignedIn) {
            this.alertsService.find().subscribe((res: Alert[]) => {
                this.alerts = res;
            });
        }
    }

    getPdfApi(): void {
        if (this.authService.isSignedIn) {
            this.companiesService.findMine().subscribe((res) => {
                this.pdfApiClient = res.pdfClientId;
                this.getPdfJobs();
            });
        }
    }

    getPdfJobs(): void {
        if (this.pdfApiClient) {
            this.pdfJobsService.find(this.pdfApiClient).subscribe((res) => {
                this.pdfJobs = res;
            });
        }
    }

    downloadPdf(job: PdfJob): void {
        if (this.activeDownload || !this.pdfApiClient) {
            return;
        }

        this.activeDownload = true;

        this.pdfJobsService.findFile(this.pdfApiClient, job._id).subscribe(
            (res) => {
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(res.body);
                downloadLink.setAttribute(
                    'download',
                    `fundracer-${dayjs(job.createdAt).format('DD.MM.YYYY-HH:mm:ss')}.zip`,
                );
                document.body.appendChild(downloadLink);
                downloadLink.click();
                this.activeDownload = false;
            },
            () => {
                this.activeDownload = false;
            },
        );
    }

    deletePdf(job: PdfJob): void {
        if (!this.pdfApiClient) {
            return;
        }

        this.pdfJobsService.delete(this.pdfApiClient, job._id).subscribe(() => {
            this.getPdfJobs();
        });
    }

    onAlertHide(alert: Alert): void {
        this.alertsService.update({ id: alert._id, dto: { hide: true } }).subscribe(() => {
            this.alerts.splice(this.alerts.indexOf(alert), 1);
        });
    }

    signOut(): void {
        sessionStorage.removeItem('filter');
        this.authService.signOut().subscribe(() => {
            this.router.navigate(['/auth/login']);
        });
    }

    private setNav(): void {
        this.navLinks = [];

        if (this.permission.DONATION_ENTRANCE) {
            this.navLinks.push({ label: 'Spendeneingang', path: '/dashboard', exact: true });
        }
        if (this.permission.DONATORS) {
            this.navLinks.push({ label: 'Spender', path: '/spender/uebersicht', exact: true });
        }
        if (this.permission.PROJECTS) {
            this.navLinks.push({ label: 'Spendenaktionen', path: '/aktionen', exact: false });
        }
        if (this.permission.SETTINGS) {
            this.navLinks.push({ label: 'Dokumente', path: '/einstellungen/dokumente', exact: false });
        }
        if (this.permission.EVALUATION) {
            this.navLinks.push({ label: 'Auswertungen', path: '/auswertungen', exact: false });
        }
    }
}
