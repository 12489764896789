import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Alert, AlertType } from 'src/app/models/alert.interface';

@Component({
    selector: 'app-alert-row',
    templateUrl: './alert-row.component.html',
    styleUrls: ['./alert-row.component.scss'],
})
export class AlertRowComponent {
    @Input() alert!: Alert;

    @Output() hide = new EventEmitter<Alert>();

    get alertType() {
        return AlertType;
    }
}
