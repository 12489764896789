import { Component, Input } from '@angular/core';
import { DonatorDto } from 'src/app/dtos/donators/donator.dto';
import { Donation } from 'src/app/models/donation.interface';
import { DonatorSearchResult } from 'src/app/models/donator-search-result.model';
import { Donator } from 'src/app/models/donator.interface';

@Component({
    selector: 'app-donator-info',
    templateUrl: './donator-info.component.html',
    styleUrls: ['./donator-info.component.scss'],
})
export class DonatorInfoComponent {
    @Input() donator!: DonatorSearchResult | Donator | DonatorDto;

    public get donations(): Donation[] {
        return (<any>this.donator).donations?.slice(0, 3) ?? [];
    }
}
