import { UntypedFormGroup } from '@angular/forms';
import dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export enum FormOutType {
    INT = 'int',
    FLOAT = 'float',
    STRING = 'str',
    DATE = 'date',
    DATE_STRING = 'date-string',
    MANUEL_DATE = 'manuel-date',
    DATE_RANGE = 'date-range',
    TIMESTAMP = 'timestamp',
    BOOL = 'boolean',
    KEY = 'KEY',
    OBJECT = 'object',
    NONE = 'none',
    AMOUNT = 'amount',
    MULTI_SELECT = 'multi-select',
}

export class FormUtil {
    private readonly form: UntypedFormGroup;

    constructor(form: UntypedFormGroup) {
        this.form = form;
    }

    static textToSlug(val: string): string {
        val = val.toLowerCase();
        val = val.replace(/ä/g, 'ae');
        val = val.replace(/ö/g, 'oe');
        val = val.replace(/ü/g, 'ue');
        val = val.replace(/ß/g, 'ss');
        val = val.replace(/ /g, '-');
        val = val.replace(/\./g, '');
        val = val.replace(/,/g, '');
        val = val.replace(/\(/g, '');
        val = val.replace(/\)/g, '');
        val = val.replace(/ /g, '-');
        val = val.replace(/[^\w-]+/g, '');

        return encodeURI(val);
    }

    static createKey(val: string): string {
        val = val.toLowerCase();
        val = val.replace(/ä/g, 'ae');
        val = val.replace(/ö/g, 'oe');
        val = val.replace(/ü/g, 'ue');
        val = val.replace(/ß/g, 'ss');
        val = val.replace(/ /g, '-');
        val = val.replace(/\./g, '');
        val = val.replace(/,/g, '');
        val = val.replace(/\(/g, '');
        val = val.replace(/\)/g, '');
        return val;
    }

    setValues(model: any, ...blackList: string[]): void {
        const blackListSet = new Set(blackList);
        Object.keys(this.form.controls).forEach((key) => {
            if (!blackListSet.has(key) && model[key] !== undefined && model[key] !== null) {
                this.form.get(key)?.setValue(model[key], { emitEvent: false });
            }
        });
    }

    getValue({ key, outType, defaultValue = null }: { key: string; outType: FormOutType; defaultValue?: any }): any {
        let val = this.form.get(key)?.value;
        if (val === null || val === undefined || String(val).length === 0) {
            return defaultValue;
        }

        switch (outType) {
            case FormOutType.STRING: {
                return String(val);
            }
            case FormOutType.INT: {
                const num = Number(val);
                return Number.isNaN(num) ? defaultValue : Math.round(num);
            }
            case FormOutType.FLOAT: {
                const num: number = Number.parseFloat(val);
                return Number.isNaN(num) ? defaultValue : num;
            }
            case FormOutType.DATE: {
                const timestamp: number = Date.parse(val);
                return Number.isNaN(timestamp) ? defaultValue : new Date(timestamp);
            }
            case FormOutType.DATE_STRING: {
                const timestamp: number = Date.parse(val);
                return Number.isNaN(timestamp) ? defaultValue : new Date(timestamp).toISOString();
            }
            case FormOutType.MANUEL_DATE: {
                const date = dayjs(val, 'DD.MM.YYYY');
                return date.isValid() ? date.toDate() : defaultValue;
            }
            case FormOutType.DATE_RANGE: {
                return typeof val === 'object' && val.startDate && val.endDate ? val : defaultValue;
            }
            case FormOutType.TIMESTAMP: {
                const timestamp: number = Date.parse(val);
                return Number.isNaN(timestamp) ? defaultValue : timestamp;
            }
            case FormOutType.BOOL: {
                return val === true || val === 'true';
            }
            case FormOutType.KEY: {
                val = FormUtil.createKey(val);
                this.form.get(key)?.setValue(val);
                return val;
            }
            case FormOutType.OBJECT: {
                return JSON.parse(val);
            }
            case FormOutType.MULTI_SELECT: {
                return val;
            }
            case FormOutType.AMOUNT: {
                return val;
            }
        }
    }

    resetForm(): void {
        Object.keys(this.form.controls).forEach((key) => {
            this.form.controls[key].setValue('');
            this.form.controls[key].setErrors(null);
        });
    }

    markAllAsTouched(): void {
        Object.keys(this.form.controls).forEach((key) => {
            this.form.controls[key].markAsTouched();
            this.form.controls[key].markAsDirty();
        });
    }
}
