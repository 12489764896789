<div class="pullee-container" #slider>
    <div
        class="pullee-text"
        [class.unlocked]="unlockStyle"
        [style.background-color]="'white'"
        [style.color]="unlockStyle ? unlockedBackgroundColor : lockedBackgroundColor"
        [style.height]="big ? '50px' : '36px'"
    >
        <div [style.padding]="big ? ' 0 85px 0 85px' : ' 0 70px 0 70px'">{{ text }}</div>
    </div>
    <input
        [class.unlocked]="unlockStyle"
        type="range"
        class="pullee"
        [class.big]="big"
        [(ngModel)]="value"
        [min]="0"
        [max]="maxValue"
        (mousedown)="unlockStartHandler()"
        (mouseup)="unlockEndHandler()"
        (touchstart)="unlockStartHandler()"
        (touchend)="unlockEndHandler()"
    />
</div>
