<div class="info">
    <h2 *ngIf="donator.mode === 'company'" id="company-header">{{ donator.companyName }}</h2>
    <h2 *ngIf="donator.mode === 'private'" id="private-header">{{ donator.firstName }} {{ donator.lastName }}</h2>

    <div class="margin">
        <div *ngIf="donator.mode === 'company' && (donator.firstName || donator.lastName)" id="company-first-last">
            {{ donator.firstName }} {{ donator.lastName }}
        </div>
        <div *ngIf="donator.mode === 'private' && donator.companyName" id="private-company-name">
            {{ donator.companyName }}
        </div>
        <div *ngIf="donator.departmentName" id="donator-department-name">{{ donator.departmentName }}</div>
        <div *ngIf="donator.street" id="donator-street">{{ donator.street }}</div>
        <div *ngIf="donator.zipCode" id="donator-zip-code">{{ donator.zipCode }} {{ donator.city }}</div>
        <div *ngIf="donator.country" id="donator-country">{{ donator.country }}</div>
    </div>

    <div class="margin">
        <div *ngIf="donator.phone" id="donator-phone">{{ donator.phone }}</div>
        <div *ngIf="donator.mobilePhone" id="donator-mobile-phone">{{ donator.mobilePhone }}</div>
        <div *ngIf="donator.email" id="donator-email">{{ donator.email }}</div>
    </div>

    <div *ngIf="donator.donations?.length" id="donator-donations">
        <div class="bold donation">Letzte Spenden</div>
        <div *ngFor="let donation of donations">
            <div class="donation" fxLayout="row" fxLayoutGap="10px">
                <div>{{ donation.type | donationType }}</div>
                <div>{{ donation.date | date }}</div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" id="donator-row-icon-text">
                    <mat-icon id="donator-row-icon">{{
                        donation.permanentDonation ? 'file_copy' : 'insert_drive_file'
                    }}</mat-icon>
                    {{ donation.permanentDonation ? 'Dauerspende' : 'Einzelspende' }}
                </div>
                <div>{{ donation.amount | numberToPrice }}</div>
            </div>
        </div>
    </div>
</div>
