<div class="headline">
    <h2>{{ data.headline }}</h2>
    <button mat-icon-button (click)="dialogRef.close(false)">
        <mat-icon>clear</mat-icon>
    </button>
</div>

<div class="body">
    <div>{{ data.body }}</div>

    <div class="warning" *ngIf="data.warning">
        {{ data.warning }}
    </div>
</div>
<div class="footer" fxLayout="row" fxLayoutAlign="space-between center">
    <button
        style="margin-right: 16px"
        mat-stroked-button
        class="converse"
        color="primary"
        (click)="dialogRef.close(false)"
    >
        Abbrechen
    </button>
    <button mat-flat-button (click)="dialogRef.close(true)" color="primary">{{ data.btn }}</button>
</div>
