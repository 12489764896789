import { Component } from '@angular/core';
import { CheckUpdatesService } from './services/chec-updates.service';
import { PushNotificationsService } from './services/push-notifications.service';
import { UpdatesService } from './services/updates.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    showNavbar = true;

    constructor(
        private readonly pushNotificationService: PushNotificationsService,
        private readonly check: CheckUpdatesService,
        private readonly updates: UpdatesService,
    ) {
        // TODO this.pushNotificationService.requestPermission().subscribe();
    }
}
