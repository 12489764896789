import { Component, OnInit, Inject } from '@angular/core';
import { S3File } from 'src/app/models/s3-file.interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DonationsService } from 'src/app/services/donations.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogFileViewerData {
    donationId: string;
    file: S3File;
}

@Component({
    selector: 'app-dialog-file-viewer',
    templateUrl: './dialog-file-viewer.component.html',
    styleUrls: ['./dialog-file-viewer.component.scss'],
})
export class DialogFileViewerComponent implements OnInit {
    private static readonly BASE64_MARKER = ';base64,';

    constructor(
        public readonly dialogRef: MatDialogRef<DialogFileViewerComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: DialogFileViewerData,
        private readonly sanitizer: DomSanitizer,
        private readonly donationService: DonationsService,
    ) {}

    pdfSrc?: Uint8Array;
    imgSrc?: SafeResourceUrl;

    rawFile?: string;

    ngOnInit(): void {
        this.donationService
            .findFile({ donationId: this.data.donationId, fileId: this.data.file._id })
            .subscribe((res: S3File) => {
                this.rawFile = res.rawFile;
                setTimeout(() => {
                    if (this.rawFile) {
                        if (res.contentType === 'application/pdf') {
                            this.pdfSrc = this.convertDataURIToBinary(this.rawFile);
                        } else {
                            this.imgSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.rawFile);
                        }
                    }
                }, 200);
            });
    }

    downloadFile(): void {
        if (this.rawFile) {
            const blob = new Blob([this.convertDataURIToBinary(this.rawFile)], { type: this.data.file.contentType });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = this.data.file.fileName;
            link.click();
        }
    }

    private convertDataURIToBinary(dataURI: string): Uint8Array {
        const base64Index =
            dataURI.indexOf(DialogFileViewerComponent.BASE64_MARKER) + DialogFileViewerComponent.BASE64_MARKER.length;
        const base64 = dataURI.substring(base64Index);
        const raw = window.atob(base64);
        const rawLength = raw.length;
        const array = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
}
