import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Alert } from '../models/alert.interface';
import { UpdateAlertDto } from '../dtos/update-alert.dto';

@Injectable({
    providedIn: 'root',
})
export class AlertsService {
    private static readonly BASE_URL = environment.api + 'alerts';

    constructor(private readonly http: HttpClient) {}

    find(): Observable<Alert[]> {
        return this.http.get<Alert[]>(`${AlertsService.BASE_URL}`);
    }

    update({ id, dto }: { id: string; dto: UpdateAlertDto }): Observable<Alert[]> {
        return this.http.post<Alert[]>(`${AlertsService.BASE_URL}/${id}`, dto);
    }
}
