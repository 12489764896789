import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogUpdatesAvailableData {
    description: string;
}

@Component({
    selector: 'app-dialog-updates-available',
    templateUrl: './dialog-updates-available.component.html',
    styleUrls: ['./dialog-updates-available.component.scss'],
})
export class DialogUpdatesAvailableComponent {
    constructor(
        public readonly dialogRef: MatDialogRef<DialogUpdatesAvailableComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: DialogUpdatesAvailableData,
    ) {}
}
