import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-add-filter',
    templateUrl: './dialog-add-filter.component.html',
    styleUrls: ['./dialog-add-filter.component.scss'],
})
export class DialogAddFilterComponent {
    name = new UntypedFormControl('', Validators.required);

    constructor(readonly dialogRef: MatDialogRef<DialogAddFilterComponent>) {}

    save(): void {
        if (this.name.invalid) {
            this.name.markAllAsTouched();
            return;
        }

        this.dialogRef.close(this.name.value);
    }
}
