import { DonationEntry } from '../models/donation-entry-list.interface';

export interface DonatorLike {
    firstName?: string;
    lastName?: string;
    companyName?: string;
}

export class DonatorFormatUtil {
    public static formatDonatorName(donator: DonatorLike | string | undefined): string {
        if (!donator || typeof donator === 'string') {
            return '-';
        } else {
            const fullName = DonatorFormatUtil.formatDonatorFullName(donator);
            const name = DonatorFormatUtil.addCompanyPrefix(fullName, donator.companyName);
            return name ? name : '-';
        }
    }

    public static formatDonationEntryName(entry: DonationEntry | string | undefined): string {
        if (!entry || typeof entry === 'string') {
            return '-';
        } else {
            const name = DonatorFormatUtil.addCompanyPrefix(entry.donatorName, entry.donatorCompanyName);
            return name ? name : '-';
        }
    }

    public static addCompanyPrefix(fullName: string | undefined, companyName: string | undefined): string | undefined {
        if (!fullName && !companyName) {
            return undefined;
        }

        let companyPrefix = '';

        if (companyName) {
            companyPrefix += companyName;
            if (fullName && fullName.trim().length > 0) {
                companyPrefix += ', ';
            }
        }

        return companyPrefix + (fullName ?? '');
    }

    public static formatDonatorFullName(donator: DonatorLike | string | undefined): string | undefined {
        if (!donator || typeof donator === 'string' || (!donator.firstName && !donator.lastName)) {
            return undefined;
        } else {
            return (donator.firstName ? donator.firstName + ' ' : '') + (donator.lastName ?? '');
        }
    }
}
